import React from 'react';
import { SimpleCard } from '../../components';
import { Box, Grid, Typography, styled, Avatar, Paper } from '@mui/material';
import { REDUX_STATES } from '../../utils/constants/reduxStates';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../store/actions/CRUDAction';
import { API_URLS } from '../../utils/constants/apiUrls';
import { Date } from '../../utils/constants/constant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { timeZoneConverter } from '../../utils/utils';
import { format } from 'date-fns';
import Loader from 'components/shared/Loader/Loader';
import { notify } from 'components/shared/Notify/notify';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const { LEADERBOARD_DETAILS, RESPONSE, LOADING } = REDUX_STATES;

export const LeaderboardDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Redux State
  const {
    [LEADERBOARD_DETAILS + RESPONSE]: leaderboardResponse = {},
    [LEADERBOARD_DETAILS + LOADING]: leaderboardLoading = false
  } = useSelector((state) => state?.Crud);

  const getLeaderboardDetails = () => {
    dispatch(
      getAction(API_URLS.GET_LEADERBOARD_DETAILS.replace(':id', id), {}, LEADERBOARD_DETAILS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    getLeaderboardDetails();
  }, []);

  return (
    <Container>
      {leaderboardLoading ? (
        <Loader loading={leaderboardLoading} />
      ) : (
        <SimpleCard title="Leaderboard Details">
          <SimpleCard
            sx={{
              pt: 1,
              pb: 2,
              px: 2,
              backgroundColor: '#f5f5f5',
              boxShadow: 'none !important',
              borderRadius: 0
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
              <Paper style={{ position: 'relative', width: 100, height: 100 }}>
                <img
                  src={leaderboardResponse?.iconUrl || ''}
                  alt={`Icon`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '5px'
                  }}
                />
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Name
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {leaderboardResponse?.name || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Created At
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {leaderboardResponse?.createdAt
                      ? format(timeZoneConverter(leaderboardResponse?.createdAt), Date.MM_DD_YYYY)
                      : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Created By
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {leaderboardResponse?.createdBy
                      ? `${leaderboardResponse?.createdBy?.firstName} ${
                          leaderboardResponse?.createdBy?.lastName || ''
                        }`
                      : null}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Visibility
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {leaderboardResponse?.isPublic ? 'Public' : 'Private'}
                  </Typography>
                </Grid> */}
                <Grid item xs={12} sm={12} lg={12}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Description
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {leaderboardResponse?.description || ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </SimpleCard>

          <Grid container spacing={2} sx={{ pt: 1.5, pl: 1 }}>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Users
              </Typography>

              {leaderboardResponse?.users?.length ? (
                <Grid container spacing={2} sx={{ pt: 1.5 }}>
                  {leaderboardResponse?.users?.map((user) => (
                    <Grid item xs={12} sm={6} lg={3} sx={{ pl: '4px !important' }}>
                      <Box
                        component={'div'}
                        sx={{
                          p: 1,
                          m: 0,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2
                        }}
                      >
                        <Avatar src={user?.photo?.pathUrl} sx={{ cursor: 'pointer' }} />
                        <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                          {`${user?.firstName} ${user?.lastName || ''}`}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  N/A
                </Typography>
              )}
            </Grid>
          </Grid>
        </SimpleCard>
      )}
    </Container>
  );
};
