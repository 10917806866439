import { styled } from '@mui/material';
import { MatxLoading, SimpleCard } from 'components';
import { notify } from 'components/shared/Notify/notify';
import PaginationTable from 'components/shared/Table/PaginationTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { CustomIconButton } from 'components/shared/Button/CustomIconButton';
import AchievementFilters from './AchievementFilters';
import { useDebounce } from 'contexts/hooks/useDebounce';
import { emptyCheck } from 'utils/utils';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const tableHeader = [
  { label: 'Title', id: 'title' },
  { label: 'Description', id: 'description' },
  { label: 'Action', id: 'actionNode', align: 'center' }
];

const { BADGES_LIST, RESPONSE, LOADING, ENTITY_LIMIT_DETAILS } = REDUX_STATES;

const AchievementsListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const debouncedSearchQuery = useDebounce(search, 600);

  const defaultUserParams = {
    page: page + 1,
    limit: rowsPerPage,
    search: emptyCheck(debouncedSearchQuery)
  };

  // Redux State
  const { [ENTITY_LIMIT_DETAILS + RESPONSE]: entityResponse = [] } = useSelector(
    (state) => state?.Crud
  );

  const {
    [BADGES_LIST + RESPONSE]: badgesResponse = [],
    [BADGES_LIST + LOADING]: badgesLoading = false
  } = useSelector((state) => state?.Crud);

  const getAchievements = (params) => {
    dispatch(getAction(API_URLS.GET_ACHIEVEMENTS, { params }, BADGES_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  const getEntitySettings = () => {
    dispatch(
      getAction(API_URLS.GET_ENTITY_LIMIT.replace(':id', '1'), [], ENTITY_LIMIT_DETAILS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    getEntitySettings();
  }, []);

  useEffect(() => {
    getAchievements(defaultUserParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, debouncedSearchQuery]);

  const normalizeTableData = (tableData) => {
    return tableData?.map((item) => {
      return {
        ...item,
        actionNode: (
          <>
            <CustomIconButton id={'edit'} path={`update/${item?.id}`} iconName="edit" />
            <CustomIconButton id={'view'} path={`${item?.id}`} iconName="keyboard_arrow_right" />
          </>
        )
      };
    });
  };

  return (
    <Container>
      <SimpleCard title="Badges" titleMargin={'0px'}>
        <AchievementFilters
          onSearch={setSearch}
          search={search}
          loading={badgesLoading}
          disabled={badgesResponse?.totalCount >= entityResponse?.entityLimit}
          allowedLimit={entityResponse?.entityLimit}
        />

        <MatxLoading isLoading={badgesLoading} />

        <PaginationTable
          data={normalizeTableData(badgesResponse?.achievements || [])}
          totalCount={badgesResponse?.totalCount || 0}
          tableHeader={tableHeader}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default AchievementsListing;
