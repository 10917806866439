import { styled, Button } from '@mui/material';
import { MatxLoading, SimpleCard } from 'components';
import { notify } from 'components/shared/Notify/notify';
import PaginationTable from 'components/shared/Table/PaginationTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { emptyCheck, timeZoneConverter } from 'utils/utils';
import { CustomIconButton } from 'components/shared/Button/CustomIconButton';
import { format } from 'date-fns';
import { Date } from 'utils/constants/constant';
import { Link } from 'react-router-dom';
import { DeleteLeaderboard } from './DeleteLeaderboard';
import { useDebounce } from 'contexts/hooks/useDebounce';
import LeaderboardFilters from './LeaderboardFilters';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const tableHeader = [
  { label: 'Name', id: 'name' },
  { label: 'Description', id: 'description' },
  // { label: 'Visibility', id: 'isPublic' },
  { label: 'Created At', id: 'createdAt' },
  { label: 'Action', id: 'actionNode', align: 'center' }
];

const { LEADERBOARD_LIST, RESPONSE, LOADING } = REDUX_STATES;

const LeaderBoardListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeleted, setIsDeleted] = useState(false);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const debouncedSearchQuery = useDebounce(search, 600);

  const defaultUserParams = {
    page: page + 1,
    limit: rowsPerPage,
    search: emptyCheck(debouncedSearchQuery)
  };

  // Redux State
  const {
    [LEADERBOARD_LIST + RESPONSE]: leaderboardResponse = [],
    [LEADERBOARD_LIST + LOADING]: leaderboardLoading = false
  } = useSelector((state) => state?.Crud);

  const getGoals = (params) => {
    dispatch(getAction(API_URLS.GET_LEADERBOARD, { params }, LEADERBOARD_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getGoals(defaultUserParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, isDeleted, debouncedSearchQuery]);

  const normalizeTableData = (tableData) => {
    return tableData?.map((item) => {
      return {
        ...item,
        createdAt: item?.createdAt
          ? format(timeZoneConverter(item?.createdAt), Date.MM_DD_YYYY)
          : null,
        // isPublic: item?.isPublic ? 'Public' : 'Private',
        actionNode: (
          <>
            <CustomIconButton id={'edit'} path={`update/${item?.id}`} iconName="edit" />
            <DeleteLeaderboard
              key={'delete'}
              id={item?.id}
              setIsDeleted={setIsDeleted}
              isDeleted={isDeleted}
            />
            <CustomIconButton id={'view'} path={`${item?.id}`} iconName="keyboard_arrow_right" />
          </>
        )
      };
    });
  };

  return (
    <Container>
      <SimpleCard title="Leaderboard" titleMargin={'0px'}>
        <LeaderboardFilters onSearch={setSearch} search={search} loading={leaderboardLoading} />

        <MatxLoading isLoading={leaderboardLoading} />

        <PaginationTable
          data={normalizeTableData(leaderboardResponse?.leaderBoards || [])}
          totalCount={leaderboardResponse?.totalCount || 0}
          tableHeader={tableHeader}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default LeaderBoardListing;
