import { styled } from '@mui/material';
import { MatxLoading, SimpleCard } from 'components';
import { notify } from 'components/shared/Notify/notify';
import PaginationTable from 'components/shared/Table/PaginationTable';
import { useDebounce } from 'contexts/hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { emptyCheck, timeZoneConverter } from 'utils/utils';
import WorkoutFilters from './WorkoutFilters';
import { CustomIconButton } from 'components/shared/Button/CustomIconButton';
import { DeleteWorkout } from './DeleteWorkout';
import { format } from 'date-fns';
import { Date } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const tableHeader = [
  { label: 'Title', id: 'title' },
  { label: 'About', id: 'about' },
  { label: 'Created At', id: 'createdAt', align: 'center' },
  { label: 'Action', id: 'actionNode', align: 'center' }
];

const { WORKOUT_LIST, RESPONSE, LOADING } = REDUX_STATES;

const WorkoutListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [createdAt, setCreatedAtChange] = useState(null);
  const [search, setSearch] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);

  const dispatch = useDispatch();
  const debouncedSearchQuery = useDebounce(search, 600);

  const defaultUserParams = {
    page: page + 1,
    limit: rowsPerPage,
    isActivated: emptyCheck(createdAt),
    search: emptyCheck(debouncedSearchQuery)
  };

  // Redux State
  const {
    [WORKOUT_LIST + RESPONSE]: workoutResponse = [],
    [WORKOUT_LIST + LOADING]: workoutLoading = false
  } = useSelector((state) => state?.Crud);

  const getWorkouts = (params) => {
    dispatch(getAction(API_URLS.GET_WORKOUTS, { params }, WORKOUT_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getWorkouts(defaultUserParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, createdAt, debouncedSearchQuery, isDeleted]);

  const normalizeTableData = (tableData) => {
    return tableData?.length
      ? tableData?.map((item) => {
          return {
            ...item,
            createdAt: item?.createdAt
              ? format(timeZoneConverter(item?.createdAt), Date.MM_DD_YYYY)
              : null,
            actionNode: (
              <>
                <CustomIconButton id={'edit'} path={`update/${item?.id}`} iconName="edit" />
                <DeleteWorkout
                  key={'delete'}
                  id={item?.id}
                  setIsDeleted={setIsDeleted}
                  isDeleted={isDeleted}
                />
                <CustomIconButton
                  id={'view'}
                  path={`${item?.id}`}
                  iconName="keyboard_arrow_right"
                />
              </>
            )
          };
        })
      : [];
  };

  return (
    <Container>
      <SimpleCard title="Workouts" titleMargin={'0px'}>
        <WorkoutFilters
          handleCreatedAtChange={setCreatedAtChange}
          createdAt={createdAt}
          onSearch={setSearch}
          search={search}
          loading={workoutLoading}
        />
        <MatxLoading isLoading={workoutLoading} />

        <PaginationTable
          data={normalizeTableData(workoutResponse?.workouts || [])}
          totalCount={workoutResponse?.totalCount || 0}
          tableHeader={tableHeader}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default WorkoutListing;
