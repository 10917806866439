import React from 'react';
import { SimpleCard } from '../../components';
import { Box, Grid, Paper, Typography, styled } from '@mui/material';
import { REDUX_STATES } from '../../utils/constants/reduxStates';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../store/actions/CRUDAction';
import { API_URLS } from '../../utils/constants/apiUrls';
import { Date } from '../../utils/constants/constant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { timeZoneConverter } from '../../utils/utils';
import { format } from 'date-fns';
import Loader from 'components/shared/Loader/Loader';
import { notify } from 'components/shared/Notify/notify';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const { GOAL_DETAILS, RESPONSE, LOADING } = REDUX_STATES;

export const GoalDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Redux State
  const {
    [GOAL_DETAILS + RESPONSE]: goalsResponse = {},
    [GOAL_DETAILS + LOADING]: goalsLoading = false
  } = useSelector((state) => state?.Crud);

  const getGoalDetail = () => {
    dispatch(getAction(API_URLS.GET_GOAL_DETAILS.replace(':id', id), {}, GOAL_DETAILS)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getGoalDetail();
  }, []);

  return (
    <Container>
      {goalsLoading ? (
        <Loader loading={goalsLoading} />
      ) : (
        <SimpleCard title="Goal Detail">
          <SimpleCard
            sx={{
              pt: 1,
              pb: 2,
              px: 2,
              backgroundColor: '#f5f5f5',
              boxShadow: 'none !important',
              borderRadius: 0
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
              {goalsResponse?.iconUrl && (
                <Paper style={{ position: 'relative', width: 100, height: 100 }}>
                  <img
                    src={goalsResponse.iconUrl}
                    alt={`Goal Icon`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '5px'
                    }}
                  />
                </Paper>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Title
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {goalsResponse?.title || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Subtitle
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {goalsResponse?.sub_title || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Created At
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {goalsResponse?.created_at
                      ? format(timeZoneConverter(goalsResponse?.created_at), Date.MM_DD_YYYY)
                      : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Updated At
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {goalsResponse?.updated_at
                      ? format(timeZoneConverter(goalsResponse?.updated_at), Date.MM_DD_YYYY)
                      : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Description
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {goalsResponse?.description || ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </SimpleCard>
        </SimpleCard>
      )}
    </Container>
  );
};
