import { request, success, failure } from '.';
import Service from '../../services/api/Service';
import { CRUD_ACTION } from '../../utils/constants/actionKeys';

/**************
  Get Action 
*************/
function getAction(url, data, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.GET_REQUEST, { key }));

      // API Calling
      const response = await Service.getService(url, data);

      if (response) {
        dispatch(success(CRUD_ACTION.GET_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.GET_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.GET_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Post Action 
*************/
function postAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.POST_REQUEST, { key }));

      // API Calling
      const response = await Service.postService(url, data, opt);

      if (response) {
        dispatch(success(CRUD_ACTION.POST_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.POST_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.POST_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Put Action 
*************/
function putAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.PUT_REQUEST, { key }));

      // API Calling
      const response = await Service.putService(url, data, opt);
      // if(response){                                                     // in case of edit there will be no response but Api will give success status
      //   dispatch(success(CRUD_ACTION.PUT_SUCCESS, { key, response }));
      // }else{
      //   dispatch(failure(CRUD_ACTION.PUT_FAILURE));
      // }
      dispatch(success(CRUD_ACTION.PUT_SUCCESS, { key, response }));
      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.PUT_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Patch Action 
*************/
function patchAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.PATCH_REQUEST, { key }));
      console.log(url, data, opt, key, 'hello ');

      // API Calling
      const response = await Service.patchService(url, data, opt);
      if (response) {
        dispatch(success(CRUD_ACTION.PATCH_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.PATCH_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.PATCH_FAILURE, { key, error }));
      throw error;
    }
  };
}

/**************
  Delete Action 
*************/
function deleteAction(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.DELETE_REQUEST, { key }));

      // API Calling
      const response = await Service.deleteService(url, data, opt);
      // if (response) {                                            // in case of edit there will be no response but Api will give success status
      //   dispatch(success(CRUD_ACTION.DELETE_SUCCESS, { key, response }));
      // } else {
      //   dispatch(failure(CRUD_ACTION.DELETE_FAILURE));
      // }
      dispatch(success(CRUD_ACTION.DELETE_SUCCESS, { key, response }));
      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.DELETE_FAILURE, { key, error }));
      throw error;
    }
  };
}

/*******************
  Update a Key Data
*******************/
function updateKeyData(data, key) {
  return async (dispatch) => {
    dispatch(request(CRUD_ACTION.UPDATE_KEY_DATA, { key, data }));
  };
}

/*******************
  Reset Key Data
*******************/
function resetKeyData() {
  return async (dispatch) => {
    dispatch(request(CRUD_ACTION.RESET_KEY_DATA));
  };
}

export { getAction, postAction, putAction, patchAction, deleteAction, updateKeyData, resetKeyData };
