import { AUTH_ACTIONS } from '../../utils/constants/actionKeys';

let initialState = {
  loading: false,
  error: false
};

const Auth = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case AUTH_ACTIONS.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };

    case AUTH_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case AUTH_ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    // Logout
    case AUTH_ACTIONS.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };

    case AUTH_ACTIONS.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };

    case AUTH_ACTIONS.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};

export default Auth;
