import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { patchAction, postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { fitnessGoalsFormSchema } from 'lib/validation/goals';
import { useNavigate } from 'react-router-dom';
import ImageUpload from 'components/ImageUpload';
import { useState } from 'react';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const initialValues = {
  title: '',
  sub_title: '',
  description: '',
  photoId: ''
};

const { CREATE_GOAL, LOADING } = REDUX_STATES;

export const CreateGoal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedImage, setselectedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);

  // Redux State
  const { [CREATE_GOAL + LOADING]: postLoading = false } = useSelector((state) => state?.Crud);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    getValues,
    reset
  } = useForm({
    resolver: yupResolver(fitnessGoalsFormSchema),
    defaultValues: initialValues
  });

  const handleFormSubmit = async () => {
    const data = getValues();
    dispatch(postAction(API_URLS.CREATE_GOAL, data, {}, CREATE_GOAL))
      .then(({ data }) => {
        selectedImage && handleImageUpload(data.id);
        notify.success('Successfully created');
        reset(initialValues);
        navigate('/goalsListing/');
      })
      .catch((e) => notify.error(e?.message));
  };

  const showAlert = () => {
    if (Object.keys(errors).length === 0) setAlert(true);
  };

  const handleImageUpload = async (goal_id) => {
    try {
      const formData = new FormData();
      formData.append('file', selectedImage);
      const { data } = await dispatch(
        postAction(
          API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'FITNESS_GOALS'),
          formData,
          {},
          ''
        )
      );
      dispatch(
        patchAction(
          API_URLS.UPDATE_GOAL.replace(':id', goal_id),
          {
            photoId: data.id
          },
          {}
        )
      );
    } catch (error) {
      console.error('Error handling image uploads:', error);
    }
  };
  const handleImageChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setselectedImage(event.target.files[0]);
  };

  return (
    <Container>
      <SimpleCard title="Create Goal">
        <form onSubmit={handleSubmit(showAlert)} noValidate>
          <ImageUpload handleImageChange={handleImageChange} selectedImage={selectedImage} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="title"
                variant="outlined"
                name="title"
                label="Title"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="sub_title"
                variant="outlined"
                name="sub_title"
                label="Subtitle"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={12}>
              <FormInput
                id="description"
                variant="outlined"
                name="description"
                label="Description"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                multiline={true}
              />
            </Grid>
          </Grid>

          <LoadingButton
            type="submit"
            color="primary"
            loading={postLoading}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
          <AlertDialog
            open={alert}
            title="Alert!"
            content={
              "Goal will be created but won't be accessible to Vahana app users until linked with a workout. Head to Workouts management to associate this goal with any workout."
            }
            actions={
              <>
                <Button
                  onClick={() => {
                    setAlert(false);
                    handleFormSubmit();
                  }}
                >
                  Proceed to Submit
                </Button>
              </>
            }
            setOpen={setAlert}
          />
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default CreateGoal;
