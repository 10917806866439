import React from 'react';
import { Box, InputLabel, TextField } from '@mui/material';

export default function Input({
  id,
  label,
  multiline,
  disabled,
  variant = 'outlined',
  onChange,
  onBlur,
  inputProps,
  ...rest
}) {
  const onKeyDown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      // Prevent the default behavior of the arrow keys
      event.preventDefault();
    }
  };

  return (
    <Box sx={{ my: 1.5 }}>
      {label ? (
        <InputLabel id={id} sx={{ color: '#2D3748' }}>
          {label}
        </InputLabel>
      ) : null}
      <TextField
        id={id}
        fullWidth
        margin="none"
        autoComplete={id}
        multiline={multiline}
        variant={variant}
        onBlur={onBlur}
        {...rest}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputProps={{
          min: 0,
          ...inputProps
        }}
        sx={{
          mt: label ? 1 : 0,
          '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-input': {
              p: multiline ? 1 : 1.8
            },
            '& fieldset': {
              borderRadius: 2,
              borderColor: '#E2E8F0'
            }
          },
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: '#00000008'
          }
        }}
      />
    </Box>
  );
}
