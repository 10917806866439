import { Icon, IconButton } from '@mui/material';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

export const CustomIconButton = ({ id, path, iconName, onClick }) => {
  return (
    <IconButton component={Link} to={path} key={id} style={{ padding: 6 }} onClick={onClick}>
      <Icon key={id} style={{ fontSize: 20 }}>
        {iconName}
      </Icon>
    </IconButton>
  );
};
