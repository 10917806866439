import * as yup from 'yup';

export const updateLeaderBoardFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
  // users: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       label: yup.string(),
  //       value: yup.string()
  //     })
  //   )
  //   .min(1, 'Required')
});

export const createLeaderBoardFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
  description: yup.string().required('Required'),
});

