import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { updateWorkoutFormSchema } from 'lib/validation/workout';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { patchAction, postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { Fragment, useState } from 'react';
import { WorkoutInstructionsForm } from './WorkoutInstructionsForm';
import { getAction } from '../../store/actions/CRUDAction';
import { useEffect } from 'react';
import FormDropdown from 'components/shared/Forms/FormDropdown';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ImageUpload from 'components/ImageUpload';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const defaultVariationValues = {
  reccomendation: '',
  reccomendationDuration: 0,
  recommenedReps: 0,
  vahanaScore: 0,
  complexity: null,
  caloriePerMinute: 0,
  duration: '',
  sets: '',
  id: 0,
  instructions: [
    {
      detail: '',
      order: 0,
      instructionId: 0
    }
  ]
};

const initialValues = {
  title: '',
  about: '',
  targetAreaId: 0,
  variations: [defaultVariationValues],
  imagesToDelete: [],
  newImages: [],
  imagesToUpdate: [],
  instructionsToDelete: []
};

const { WORKOUT_DETAIL, UPDATE_WORKOUT, TARGET_AREAS_LIST, LOADING, RESPONSE, ERROR } =
  REDUX_STATES;

const normalizeDropdownList = (data) => {
  return data?.map((item) => {
    return {
      label: item?.title,
      value: item?.id
    };
  });
};

const complexities = [
  { label: 'Beginner', value: 0 },
  { label: 'Intermediate', value: 1 },
  { label: 'Expert', value: 2 }
];

export const UpdateWorkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [instructionsToDelete, setInstructionsToDelete] = useState([]);
  const { id } = useParams();
  const [selectedImage, setselectedImage] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    [TARGET_AREAS_LIST + RESPONSE]: targetAreasResponse = [],
    [TARGET_AREAS_LIST + LOADING]: targetAreasLoading = false
  } = useSelector((state) => state?.Crud);

  const {
    [UPDATE_WORKOUT + ERROR]: updateError = false,
    [UPDATE_WORKOUT + LOADING]: workoutUpdating = false
  } = useSelector((state) => state?.Crud);

  const {
    [WORKOUT_DETAIL + RESPONSE]: workoutResponse = {},
    [WORKOUT_DETAIL + LOADING]: workoutLoading = false
  } = useSelector((state) => state?.Crud);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    reset
  } = useForm({
    resolver: yupResolver(updateWorkoutFormSchema),
    defaultValues: initialValues
  });

  const { fields: variationsFields } = useFieldArray({
    control,
    name: 'variations'
  });

  const getTargetAreas = () => {
    dispatch(getAction(API_URLS.GET_TARGET_AREAS, [], TARGET_AREAS_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  const getWorkoutDetail = () => {
    dispatch(getAction(API_URLS.GET_WORKOUT_DETAIL.replace(':id', id), {}, WORKOUT_DETAIL)).catch(
      (e) => notify.error(e?.message)
    );
  };

  useEffect(() => {
    getTargetAreas();
    getWorkoutDetail();
  }, []);

  useEffect(() => {
    if (workoutResponse) {
      const updatedDetails = {
        ...workoutResponse,
        variations: workoutResponse?.variations?.map((item) => {
          return {
            ...item,
            complexity: complexities?.find(
              (complexity) => complexity?.label.toUpperCase() === item?.complexity?.toUpperCase()
            )?.value,
            instructions: item?.instructions?.map((inst) => {
              return { ...inst, instructionId: inst?.id };
            })
          };
        }),
        imagesToDelete: [],
        newImages: [],
        imagesToUpdate: [],
        instructionsToDelete: [],
        targetAreaId: workoutResponse?.workoutTargetAreas?.targetAreas?.id
      };
      reset(updatedDetails);
      setselectedImage(workoutResponse?.images?.map((im) => im.url));
      setSelectedIcon(workoutResponse?.photoUrl);
    }
  }, [workoutResponse]);

  const normalizeVariations = (variations) => {
    console.log(variations);
    return variations?.map((variation) => {
      return {
        ...variation,
        complexity: complexities?.find((item) => item.value === variation?.complexity)?.label,
        instructions: variation?.instructions?.map((item, idx) => {
          return { ...item, order: idx + 1 };
        })
      };
    });
  };

  const handleUpdateWorkout = async (data, photoId = '') => {
    const imagesToDelete =
      workoutResponse?.images
        .filter((image) => !selectedImage.includes(image.url))
        .map((im) => im.id) || [];
    const newImages = selectedImage.filter(
      (imageUrl) => !workoutResponse?.images.some((image) => image.url === imageUrl)
    );
    newImages.length && handleImageUpload(newImages);
    const payload = {
      ...data,
      title: data?.title,
      about: data?.about,
      variations: normalizeVariations(data?.variations),
      imagesToDelete,
      instructionsToDelete: instructionsToDelete || [],
      targetAreas: { id: data?.targetAreaId },
      photoId
    };
    dispatch(patchAction(API_URLS.UPDATE_WORKOUT.replace(':id', id), payload, {}, UPDATE_WORKOUT))
      .then(() => {
        notify.success('Successfully updated');
        navigate('/workoutListing/');
      })
      .catch((e) => notify.error(e?.message));
  };

  const handleImageUpload = async (newImages) => {
    try {
      newImages.map(async (image) => {
        const formData = new FormData();
        formData.append('file', image);
        const { data } = dispatch(
          postAction(
            API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'EXERCISES') + `?workout_id=${id}`,
            formData,
            {},
            ''
          )
        );
        return data;
      });
    } catch (error) {
      console.error('Error handling image uploads:', error);
    }
  };

  const handleFormSubmit = async (values) => {
    // for workout icon
    if (typeof selectedIcon !== 'string') {
      const formData = new FormData();
      formData.append('file', selectedIcon);
      dispatch(
        postAction(API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'WORKOUTS'), formData, {}, '')
      )
        .then(({ data }) => {
          handleUpdateWorkout(values, data?.id);
        })
        .catch((e) => notify.error(e?.message));
    } else {
      handleUpdateWorkout(values);
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const largeFileFound = files?.find((file) => file?.size > ALLOWED_IMG_SIZE);
    if (largeFileFound) setOpen(true);
    else setselectedImage([...selectedImage, ...files]);
  };

  const handleIconChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setSelectedIcon(event.target.files[0]);
  };

  const removeImage = (indexToRemove) => {
    setselectedImage((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
  };

  return (
    <Container>
      <SimpleCard title="Update Workout">
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <ImageUpload
            handleImageChange={handleIconChange}
            message="Upload Workout Icon"
            selectedImage={selectedIcon}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="title"
                variant="outlined"
                name="title"
                label="Title"
                placeholder="Type here"
                type="text"
                disabled={workoutUpdating || workoutLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <FormDropdown
                id={'targetAreaId'}
                variant="outlined"
                name={'targetAreaId'}
                label="Target Area"
                placeholder="Select target area"
                menuItems={normalizeDropdownList(targetAreasResponse)}
                disabled={workoutUpdating || workoutLoading || targetAreasLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={12}>
              <FormInput
                id="about"
                variant="outlined"
                name="about"
                label="About"
                placeholder="Type here"
                type="text"
                disabled={workoutUpdating || workoutLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                multiline={true}
              />
            </Grid>
          </Grid>

          <Typography variant="subtitle1" sx={{ pb: 1, pt: 2, fontWeight: 500 }}>
            Variations:
          </Typography>
          {variationsFields.map((item, index) => {
            return (
              <Fragment key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`reccomendation`}
                      variant="outlined"
                      name={`variations[${index}].reccomendation`}
                      label="Recommendation"
                      placeholder="Type here"
                      type="text"
                      disabled={workoutUpdating || workoutLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`recommenedReps`}
                      variant="outlined"
                      name={`variations[${index}].recommenedReps`}
                      label="Recommended Reps"
                      placeholder="Type here"
                      type="number"
                      disabled={workoutUpdating || workoutLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`reccomendationDuration`}
                      variant="outlined"
                      name={`variations[${index}].reccomendationDuration`}
                      label="Recommended Duration"
                      placeholder="Type here"
                      type="number"
                      disabled={workoutUpdating || workoutLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`vahanaScore`}
                      variant="outlined"
                      name={`variations[${index}].vahanaScore`}
                      label="Vahana Score"
                      placeholder="Type here"
                      type="number"
                      disabled={workoutUpdating || workoutLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`sets`}
                      variant="outlined"
                      name={`variations[${index}].sets`}
                      label="Sets"
                      placeholder="Type here"
                      type="text"
                      disabled={workoutUpdating || workoutLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormDropdown
                      id={`complexity`}
                      variant="outlined"
                      name={`variations[${index}].complexity`}
                      label="Complexity"
                      placeholder="Select Complexity"
                      disabled={workoutUpdating || workoutLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                      menuItems={complexities}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <WorkoutInstructionsForm
                      control={control}
                      errors={errors?.variations?.[index]}
                      name={`variations[${index}].instructions`}
                      loading={workoutUpdating || workoutLoading}
                      touched={touched}
                      handleBlur={handleBlur}
                      // setValue={setValue}
                      isUpdateMode={true}
                      setInstructionsToDelete={setInstructionsToDelete}
                      instructionsToDelete={instructionsToDelete}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}

          <ImageUpload
            handleImageChange={handleImageChange}
            disabled={selectedImage?.length === 4}
            minImages={2}
            removeImage={removeImage}
            selectedImage={selectedImage}
            message="Upload Workout Images"
            width={200}
            height={150}
          />

          <LoadingButton
            type="submit"
            color="primary"
            loading={workoutUpdating || workoutLoading}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default UpdateWorkout;
