import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

export default function Dropdown({
  label,
  placeholder,
  menuItems,
  error,
  helperText,
  fullWidth = false,
  value = '',
  ...rest
}) {
  const renderMenuItems = (items) => {
    return items?.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  };

  return (
    <Box sx={{ my: 1.5 }}>
      {label ? <InputLabel sx={{ mb: 1, color: '#2D3748' }}>{label}</InputLabel> : null}
      <FormControl
        fullWidth={fullWidth}
        error={error}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 2,
            borderColor: '#E2E8F0'
          },
          '& .MuiOutlinedInput-input': {
            py: '14.4px'
          }
        }}
      >
        <Select
          fullWidth
          margin="dense"
          displayEmpty
          value={value}
          {...rest}
          MenuProps={{
            PaperProps: { sx: { maxHeight: { xs: 155, md: 180 } } }
          }}
          renderValue={
            value !== ''
              ? undefined
              : () => <span style={{ color: '#ccc' }}>{placeholder || label || 'None'}</span>
          }
        >
          {renderMenuItems(menuItems)}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </Box>
  );
}
