import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
// import { fitnessGoalsFormSchema } from 'lib/validation/goals';
import { useNavigate } from 'react-router-dom';
// import FormSwitch from 'components/shared/Forms/FormSwitch';
// import FormMultiSearchableDropdown from 'components/shared/Forms/FormMultiSearchableDropdown';
import { useState } from 'react';
import { createLeaderBoardFormSchema } from 'lib/validation/leaderboard';
import ImageUpload from 'components/ImageUpload';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

// const normalizeDropdownList = (data) => {
//   return data?.map((item) => {
//     return {
//       label: `${item?.firstName} ${item?.lastName || ''}`?.trim(),
//       value: item?.id
//     };
//   });
// };

const initialValues = {
  name: '',
  iconId: '',
  description: '',
  isPublic: true,
  users: []
};

const { CREATE_LEADERBOARD, LOADING } = REDUX_STATES;

export const CreateLeaderboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedImage, setselectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  // Redux State
  const { [CREATE_LEADERBOARD + LOADING]: postLoading = false } = useSelector(
    (state) => state?.Crud
  );

  // // Redux State
  // const {
  //   [USER_LIST + RESPONSE]: usersListResponse = [],
  //   [USER_LIST + LOADING]: usersListLoading = false
  // } = useSelector((state) => state?.Crud);

  // const getUsers = () => {
  //   dispatch(getAction(API_URLS.GET_USERS, [], USER_LIST)).catch((e) => notify.error(e?.message));
  // };

  // useEffect(() => {
  //   getUsers();
  // }, []);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    // setValue,
    handleBlur,
    reset
  } = useForm({
    resolver: yupResolver(createLeaderBoardFormSchema),
    defaultValues: initialValues
  });

  const handleCreateLeaderboard = async (values, data) => {
    const payload = {
      ...values,
      iconId: data?.id || ''
    };
    dispatch(postAction(API_URLS.CREATE_LEADERBOARD, payload, {}, CREATE_LEADERBOARD))
      .then(() => {
        notify.success('Successfully created');
        reset(initialValues);
        navigate('/leaderboardListing/');
      })
      .catch((e) => notify.error(e?.message));
  };

  const handleImageUpload = async (values) => {
    // First upload image
    const formData = new FormData();
    formData.append('file', selectedImage);
    dispatch(
      postAction(
        API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'LEADERS_BOARD'),
        formData,
        {},
        ''
      )
    )
      .then(({ data }) => {
        // Create leaderboard once icon image is uploaded
        handleCreateLeaderboard(values, data);
      })
      .catch((e) => notify.error(e?.message));
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setselectedImage(event.target.files[0]);
  };

  const handleFormSubmit = async (data) => {
    await handleImageUpload(data);
  };

  return (
    <Container>
      <SimpleCard title="Create Leaderboard">
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <ImageUpload handleImageChange={handleImageChange} selectedImage={selectedImage} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="name"
                variant="outlined"
                name="name"
                label="Name"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} lg={3} sx={{ ml: { lg: 2 } }}>
              <FormSwitch
                id="isPublic"
                variant="outlined"
                name="isPublic"
                label="Visibility"
                switchLabel="Public"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} lg={12}>
              <FormInput
                id="description"
                variant="outlined"
                name="description"
                label="Description"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                multiline={true}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} lg={4}>
              <FormMultiSearchableDropdown
                id={'users'}
                variant="outlined"
                name={'users'}
                label="Users"
                placeholder="Select users"
                menuItems={normalizeDropdownList(usersListResponse?.users) || []}
                disabled={postLoading || usersListLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                fullWidth={true}
                onChange={(_, data) => {
                  setValue('users', data, { shouldValidate: true });
                }}
              />
            </Grid> */}
          </Grid>

          <LoadingButton
            type="submit"
            color="primary"
            loading={postLoading}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default CreateLeaderboard;
