import 'animate.css/animate.min.css';
import { cssTransition, toast } from 'react-toastify';

const fadeOut = cssTransition({
  enter: 'animate__animated animate__bounceIn',
  exit: 'animate__animated animate__fadeOut animate__slower'
});

export const notify = {
  options: {
    transition: fadeOut,
    hideProgressBar: true,
    autoClose: 2000
  },

  toast(message) {
    return toast?.(message, this.options);
  },

  success(message) {
    return toast?.success(message, this.options);
  },

  error(message, options) {
    return toast?.error(message, { ...this.options, ...options });
  },

  dismissAll() {
    return toast.dismiss();
  }
};
