import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { patchAction, postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { getAction } from '../../store/actions/CRUDAction';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fitnessGoalsFormSchema } from 'lib/validation/goals';
import { useNavigate } from 'react-router-dom';
import ImageUpload from 'components/ImageUpload';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';
const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const initialValues = {
  title: '',
  sub_title: '',
  description: '',
  photoId: ''
};

const { GOAL_DETAILS, UPDATE_GOAL, LOADING, RESPONSE, ERROR } = REDUX_STATES;

export const UpdateGoal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedImage, setselectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    [UPDATE_GOAL + ERROR]: updateError = false,
    [UPDATE_GOAL + LOADING]: goalUpdating = false
  } = useSelector((state) => state?.Crud);

  const {
    [GOAL_DETAILS + RESPONSE]: goalsResponse = {},
    [GOAL_DETAILS + LOADING]: goalsLoading = false
  } = useSelector((state) => state?.Crud);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    reset
  } = useForm({
    resolver: yupResolver(fitnessGoalsFormSchema),
    defaultValues: initialValues
  });

  const getGoalDetails = () => {
    dispatch(getAction(API_URLS.GET_GOAL_DETAILS.replace(':id', id), {}, GOAL_DETAILS)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getGoalDetails();
  }, []);

  useEffect(() => {
    if (goalsResponse) {
      const updatedDetails = {
        ...goalsResponse,
        photoId: goalsResponse?.photo?.id
      };
      reset(updatedDetails);
      setselectedImage(goalsResponse?.iconUrl);
    }
  }, [goalsResponse]);

  // const handleFormSubmit = async (data) => {
  //   const payload = {
  //     ...data
  //   };

  //   dispatch(
  //     patchAction(API_URLS.UPDATE_GOAL.replace(':id', id), (data = payload), {}, UPDATE_GOAL)
  //   )
  //     .then(() => {
  //       notify.success('Successfully updated');
  //       navigate('/goalsListing/');
  //     })
  //     .catch((e) => notify.error(e?.message));
  // };

  const handleImageUpload = async (goal_id) => {
    // First upload image
    try {
      const formData = new FormData();
      formData.append('file', selectedImage);
      const { data } = await dispatch(
        postAction(
          API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'FITNESS_GOALS'),
          formData,
          {},
          ''
        )
      );
      dispatch(
        patchAction(
          API_URLS.UPDATE_GOAL.replace(':id', goal_id),
          {
            photoId: data.id
          },
          {},
          UPDATE_GOAL
        )
      );
    } catch (e) {
      notify.error(e?.message);
    }
  };

  const handleFormSubmit = async (values) => {
    dispatch(patchAction(API_URLS.UPDATE_GOAL.replace(':id', id), values, {}, UPDATE_GOAL))
      .then(({ data }) => {
        if (typeof selectedImage !== 'string') handleImageUpload(data?.id);
        notify.success('Successfully updated');
        reset(initialValues);
        navigate('/goalsListing/');
      })
      .catch((e) => notify.error(e?.message));
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setselectedImage(event.target.files[0]);
  };

  return (
    <Container>
      <SimpleCard title="Update Goal">
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <ImageUpload handleImageChange={handleImageChange} selectedImage={selectedImage} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="title"
                variant="outlined"
                name="title"
                label="Title"
                placeholder="Type here"
                type="text"
                disabled={goalUpdating || goalsLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="sub_title"
                variant="outlined"
                name="sub_title"
                label="Subtitle"
                placeholder="Type here"
                type="text"
                disabled={goalUpdating || goalsLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={12}>
              <FormInput
                id="description"
                variant="outlined"
                name="description"
                label="Description"
                placeholder="Type here"
                type="text"
                disabled={goalUpdating || goalsLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                multiline={true}
              />
            </Grid>
          </Grid>

          <LoadingButton
            type="submit"
            color="primary"
            loading={goalUpdating || goalsLoading}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default UpdateGoal;
