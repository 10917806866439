import { Fragment, useState } from 'react';
import { Button, styled, IconButton, Paper, Grid, Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from './shared/AlertDialogue/AlertDialoge';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const DisplayImage = (image, index, removeImage, width = 100, height = 100) => {
  return (
    <Grid item>
      <Paper style={{ position: 'relative', width: width, height: height }}>
        <img
          src={typeof image === 'string' ? image : URL.createObjectURL(image)}
          alt={`Img ${index}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '5px'
          }}
        />
        {removeImage && (
          <IconButton
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: 'red'
            }}
            onClick={() => removeImage(index)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Paper>
    </Grid>
  );
};

function ImageUpload({
  handleImageChange,
  disabled = false,
  removeImage,
  selectedImage,
  message = '',
  width = 100,
  height = 100
}) {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start' }}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          onChange={handleImageChange}
          disabled={disabled}
          startIcon={<CloudUploadIcon />}
        >
          {message || `Upload Image${Array.isArray(selectedImage) ? 's' : ''}`}
          <VisuallyHiddenInput type="file" accept="image/*" />
        </Button>

        {selectedImage || Array.isArray(selectedImage) ? null : (
          <Typography variant="caption" sx={{ color: 'red' }}>
            *Required
          </Typography>
        )}
      </Box>

      <div style={{ marginBottom: 8, paddingTop: 10 }}>
        <Grid container spacing={2}>
          {Array.isArray(selectedImage) ? (
            selectedImage?.map((image, index) => (
              <Fragment key={index}>
                {DisplayImage(image, index, removeImage, width, height)}
              </Fragment>
            ))
          ) : (
            <>{selectedImage && DisplayImage(selectedImage)}</>
          )}
        </Grid>
      </div>
    </>
  );
}

export default ImageUpload;
