import { InputAdornment, OutlinedInput } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { Search } from '@mui/icons-material';
import Loader from 'components/shared/Loader/Loader';

export default function SearchInput({
  placeholder,
  sx,
  value,
  onSearch,
  matchDownLG,
  searchInputIcon,
  loading = false
}) {
  const [expandsSearch, setExpandSearch] = useState(false);
  return (
    <div>
      {matchDownLG || expandsSearch ? (
        <OutlinedInput
          sx={{
            ...sx,
            pl: 0.5
          }}
          type="search"
          placeholder={placeholder}
          size="small"
          value={value}
          onChange={(event) => {
            onSearch(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          onMouseLeave={() => setExpandSearch(false)}
          startAdornment={
            <InputAdornment position="start">
              {matchDownLG ? (
                <IconButton type="button" aria-label="search">
                  <Search />
                </IconButton>
              ) : null}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Loader loading={loading} />
            </InputAdornment>
          }
        />
      ) : (
        <IconButton
          type="button"
          aria-label="search"
          sx={{ pr: '19px' }}
          onClick={() => setExpandSearch(true)}
        >
          <img
            src={searchInputIcon || '/assets/icons/searchIcon.svg'}
            alt="icon"
            width={22}
            height={21}
          />
        </IconButton>
      )}
    </div>
  );
}
