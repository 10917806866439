import { useFieldArray } from 'react-hook-form';
import { Fragment } from 'react';
import { Typography, Grid, Button, InputLabel } from '@mui/material';
import FormInput from 'components/shared/Forms/FormInput';
import DeleteIcon from '@mui/icons-material/Delete';

export const WorkoutInstructionsForm = ({
  control,
  errors,
  name,
  loading,
  touched,
  handleBlur,
  isUpdateMode = false,
  setInstructionsToDelete,
  instructionsToDelete
}) => {

  const {
    fields: instructionFields,
    append: instructionAppend,
    remove: instructionsRemove,
  } = useFieldArray({
    control,
    name: name
  });

  return (
    <>
      <InputLabel id={name} sx={{ color: '#2D3748' }}>
        Instructions:
      </InputLabel>
      {instructionFields?.map((item, index) => {
        return (
          <Fragment key={item?.id}>
            <Grid container spacing={1} sx={{ alignItems: 'center' }}>
              <Grid item xs={0.5} sm={0.5} lg={0.5}>
                <Typography sx={{ textAlign: 'center' }}>{index + 1}.</Typography>
              </Grid>
              <Grid
                item
                xs={instructionFields?.length > 1 || isUpdateMode ? 11 : 11.5}
                sm={instructionFields?.length > 1 || isUpdateMode ? 11 : 11.5}
                lg={instructionFields?.length > 1 || isUpdateMode ? 11 : 11.5}
              >
                <FormInput
                  id={`detail`}
                  variant="outlined"
                  name={`${name}[${index}].detail`}
                  label=""
                  placeholder="Type here"
                  type="text"
                  disabled={loading}
                  control={control}
                  errors={errors?.instructions?.[index]}
                  touched={touched}
                  onBlur={handleBlur}
                />
              </Grid>
              {instructionFields?.length > 1 || isUpdateMode ? (
                <Grid item xs={0.5} sm={0.5} lg={0.5} sx={{ justifyContent: 'flex-end' }}>
                  <DeleteIcon
                    sx={{ fontSize: '20px', cursor: 'pointer', mt: 1, float: 'right' }}
                    onClick={() => {
                      setInstructionsToDelete([...instructionsToDelete, item?.instructionId]);
                      instructionsRemove(index);
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Fragment>
        );
      })}

      <Grid item xs={12} sm={6} lg={4} sx={{ float: 'right' }}>
        <Button
          onClick={() => {
            instructionAppend({ detail: '', order: instructionFields?.length + 1 });
          }}
        >
          + Add more instructions
        </Button>
      </Grid>
    </>
  );
};
