import { Card, Box, styled } from '@mui/material';

const CardRoot = styled(Card)({
  height: '100%',
  padding: '20px 24px'
});

const CardTitle = styled('div')(({ subtitle }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize'
}));

const SimpleCard = ({ children, title, subtitle, sx, titleMargin }) => {
  return (
    <CardRoot elevation={6} sx={sx}>
      {title && (
        <CardTitle
          subtitle={subtitle}
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            marginBottom: titleMargin || (!subtitle && '16px')
          }}
        >
          {title}
        </CardTitle>
      )}
      {subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>}
      {children}
    </CardRoot>
  );
};

export default SimpleCard;
