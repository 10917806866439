import { Button, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { patchAction, postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import Loading from 'components/MatxLoading';
import { useEffect, useState } from 'react';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import Loader from 'components/shared/Loader/Loader';
import { notify } from 'components/shared/Notify/notify';

const { UPDATE_STATUS, RESPONSE, ERROR, LOADING } = REDUX_STATES;

export const UserStatus = ({ id, isActivated, setUserStatusChanged, userStatusChanged }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [tempStatus, setTempStatus] = useState(false); // Use tempStatus to track changes

  useEffect(() => setTempStatus(isActivated), [isActivated]);

  const {
    [UPDATE_STATUS + ERROR]: userError = false,
    [UPDATE_STATUS + LOADING]: statusLoading = false
  } = useSelector((state) => state?.Crud);

  const handleStatusChange = () => {
    dispatch(
      patchAction(
        API_URLS.ACTIVATE_USER.replace(':id', id),
        { id, isActivated: !isActivated },
        {},
        UPDATE_STATUS
      )
    )
      .then(({ data }) => {
        setUserStatusChanged(!userStatusChanged);
        if (!data?.isActivated)
          dispatch(postAction(API_URLS.DELETE_USER_SESSIONS + `?userId=${id}`, {}, {}, ''));
      })
      .catch((e) => notify.error(e?.message));
    setOpen(false);
  };

  return (
    <div key={id}>
      <AlertDialog
        open={open}
        title="Confirmation"
        content={`Do you want to change the user status ?`}
        actions={
          <>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button onClick={() => handleStatusChange()}>Yes</Button>
          </>
        }
        setOpen={setOpen}
      />
      <Loader loading={statusLoading} />
      {statusLoading ? null : (
        <Switch
          checked={tempStatus}
          onChange={() => setOpen(true)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      )}
    </div>
  );
};
