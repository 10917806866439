import { styled, Button } from '@mui/material';
import { MatxLoading, SimpleCard } from 'components';
import { notify } from 'components/shared/Notify/notify';
import PaginationTable from 'components/shared/Table/PaginationTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { timeZoneConverter } from 'utils/utils';
import { CustomIconButton } from 'components/shared/Button/CustomIconButton';
import { format } from 'date-fns';
import { Date } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const tableHeader = [
  { label: 'Name', id: 'entityName' },
  { label: 'Limit', id: 'entityLimit' },
  { label: 'Created At', id: 'createdAt', align: 'center' },
  { label: 'Action', id: 'actionNode', align: 'center' }
];

const { ENTITY_LIMIT_SETTINGS, RESPONSE, LOADING } = REDUX_STATES;

const EntityLimitsListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  const defaultUserParams = {
    page: page + 1,
    limit: rowsPerPage
  };

  // Redux State
  const {
    [ENTITY_LIMIT_SETTINGS + RESPONSE]: entityResponse = [],
    [ENTITY_LIMIT_SETTINGS + LOADING]: entitesLoading = false
  } = useSelector((state) => state?.Crud);

  const getLimits = (params) => {
    dispatch(
      getAction(API_URLS.GET_ENTITY_LIMIT_SETTINGS, { params }, ENTITY_LIMIT_SETTINGS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    getLimits(defaultUserParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const normalizeTableData = (tableData) => {
    return tableData?.map((item) => {
      return {
        ...item,
        createdAt: item?.createdAt
          ? format(timeZoneConverter(item?.createdAt), Date.MM_DD_YYYY)
          : null,
        actionNode: (
          <>
            <CustomIconButton id={'edit'} path={`update/${item?.id}`} iconName="edit" />
          </>
        )
      };
    });
  };

  return (
    <Container>
      <SimpleCard title="Fitness Goals" titleMargin={'0px'}>
        <MatxLoading isLoading={entitesLoading} />

        <PaginationTable
          data={normalizeTableData(entityResponse || [])}
          totalCount={entityResponse?.length || 0}
          tableHeader={tableHeader}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default EntityLimitsListing;
