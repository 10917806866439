/*
  General Response Handler
*/

import storageService from '../storageService/StorageService';

export function handleResponse(response) {
  if (response && (response?.status === 200 || response?.status === 204)) {
    // in case of PUT/DELETE, Api will respond 204
    return response?.data;
  } else {
    handleError(response);
  }
}

export function handleError(error) {
  if (error?.response?.status === 401) {
    storageService.deleteLoginData(); // clear session
    window.location.reload();
  }
  throw error;
}
