export const AUTH_ACTIONS = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE'
};

export const CRUD_ACTION = {
  GET_REQUEST: 'GET_REQUEST',
  GET_SUCCESS: 'GET_SUCCESS',
  GET_FAILURE: 'GET_FAILURE',

  POST_REQUEST: 'POST_REQUEST',
  POST_SUCCESS: 'POST_SUCCESS',
  POST_FAILURE: 'POST_FAILURE',

  PUT_REQUEST: 'PUT_REQUEST',
  PUT_SUCCESS: 'PUT_SUCCESS',
  PUT_FAILURE: 'PUT_FAILURE',

  PATCH_REQUEST: 'PATCH_REQUEST',
  PATCH_SUCCESS: 'PATCH_SUCCESS',
  PATCH_FAILURE: 'PATCH_FAILURE',

  DELETE_REQUEST: 'DELETE_REQUEST',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILURE: 'DELETE_FAILURE',

  UPDATE_KEY_DATA: 'UPDATE_KEY_DATA',
  RESET_KEY_DATA: 'RESET_KEY_DATA'
};
