import React from 'react';
import { SimpleCard } from '../../components';
import { Box, Grid, Paper, Typography, styled } from '@mui/material';
import { REDUX_STATES } from '../../utils/constants/reduxStates';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../store/actions/CRUDAction';
import { API_URLS } from '../../utils/constants/apiUrls';
import { Date } from '../../utils/constants/constant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { timeZoneConverter } from '../../utils/utils';
import { format } from 'date-fns';
import Loader from 'components/shared/Loader/Loader';
import { notify } from 'components/shared/Notify/notify';
import { List, ListItem, ListItemText } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const { WORKOUT_DETAIL, RESPONSE, LOADING } = REDUX_STATES;

export const WorkoutDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Redux State
  const {
    [WORKOUT_DETAIL + RESPONSE]: workoutResponse = {},
    [WORKOUT_DETAIL + LOADING]: workoutLoading = false
  } = useSelector((state) => state?.Crud);

  const getWorkoutDetail = () => {
    dispatch(getAction(API_URLS.GET_WORKOUT_DETAIL.replace(':id', id), {}, WORKOUT_DETAIL)).catch(
      (e) => notify.error(e?.message)
    );
  };

  useEffect(() => {
    getWorkoutDetail();
  }, []);

  const targetAreas = workoutResponse?.workoutTargetAreas?.targetAreas;
  return (
    <Container>
      {workoutLoading ? (
        <Loader loading={workoutLoading} />
      ) : (
        <SimpleCard title="Workout Detail">
          <SimpleCard
            sx={{
              pt: 1,
              pb: 2,
              px: 2,
              backgroundColor: '#f5f5f5',
              boxShadow: 'none !important',
              borderRadius: 0
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
              <Paper style={{ position: 'relative', width: 100, height: 100 }}>
                <img
                  src={workoutResponse?.photoUrl}
                  alt={`Workout Icon`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '5px'
                  }}
                />
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Title
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>{workoutResponse?.title || ''}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Fitness Goal
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {workoutResponse?.fitnessGoals ? workoutResponse?.fitnessGoals?.[0] : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Created At
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {workoutResponse?.createdAt
                      ? format(timeZoneConverter(workoutResponse?.createdAt), Date.MM_DD_YYYY)
                      : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    About
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>{workoutResponse?.about || ''}</Typography>
                </Grid>
              </Grid>
            </Box>
          </SimpleCard>

          <Typography variant="subtitle1" sx={{ pb: 1, pt: 2, fontWeight: 500 }}>
            Target Area:
          </Typography>

          <Grid container spacing={1} sx={{ pl: 1 }}>
            <Grid item xs={12} sm={2} lg={1.5}>
              <img
                src={targetAreas?.photoUrl || ''}
                alt={`Target Area`}
                style={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Title
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {targetAreas?.title || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Created At
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {workoutResponse?.createdAt
                  ? format(timeZoneConverter(targetAreas?.createdAt), Date.MM_DD_YYYY)
                  : null}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Updated At
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {workoutResponse?.updatedAt
                  ? format(timeZoneConverter(targetAreas?.updatedAt), Date.MM_DD_YYYY)
                  : null}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="subtitle1" sx={{ pb: 1, pt: 2, fontWeight: 500 }}>
            Variation:
          </Typography>

          {workoutResponse?.variations?.map((variation, index) => (
            <Grid container spacing={1} sx={{ pl: 1 }} key={index}>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Complexity
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {variation?.complexity || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Sets
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>{variation?.sets || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Recommendation
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>{variation?.reccomendation || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Recommended Duration
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>{variation?.reccomendationDuration || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Recommended Reps
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>{variation?.recommenedReps || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Vahana Score
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>{variation?.vahanaScore || ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Instructions
                </Typography>
                <List sx={{ listStyle: 'decimal', pl: 2 }}>
                  {variation?.instructions?.map((instruction, index) => (
                    <ListItem key={index} sx={{ display: 'list-item', p: 0 }}>
                      <ListItemText primary={instruction.detail} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          ))}

          <Typography variant="subtitle1" sx={{ pb: 1, pt: 2, fontWeight: 500 }}>
            Workout Images:
          </Typography>
          <Grid container spacing={2}>
            {workoutResponse?.images?.map((image, index) => (
              <Grid item key={index}>
                <Paper style={{ position: 'relative', width: 200, height: 150 }}>
                  <img
                    src={image.url}
                    alt={`Workout ${index}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '5px'
                    }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </SimpleCard>
      )}
    </Container>
  );
};
