import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { patchAction, postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { getAction } from '../../store/actions/CRUDAction';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import FormSwitch from 'components/shared/Forms/FormSwitch';
import { updateLeaderBoardFormSchema } from 'lib/validation/leaderboard';
import ImageUpload from 'components/ImageUpload';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

// const normalizeDropdownList = (data) => {
//   return data?.map((item) => {
//     return {
//       label: `${item?.firstName} ${item?.lastName || ''}`?.trim(),
//       value: item?.id
//     };
//   });
// };

const initialValues = {
  name: '',
  iconId: '',
  description: '',
  isPublic: true,
  users: []
};

const { USER_LIST, LEADERBOARD_DETAILS, UPDATE_LEADERBOARD, LOADING, RESPONSE, ERROR } =
  REDUX_STATES;

export const UpdateLeaderboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedImage, setselectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    [UPDATE_LEADERBOARD + ERROR]: updateError = false,
    [UPDATE_LEADERBOARD + LOADING]: leaderboardUpdating = false
  } = useSelector((state) => state?.Crud);

  const {
    [LEADERBOARD_DETAILS + RESPONSE]: leaderboardResponse = {},
    [LEADERBOARD_DETAILS + LOADING]: leaderboardLoading = false
  } = useSelector((state) => state?.Crud);

  // // Redux State
  // const {
  //   [USER_LIST + RESPONSE]: usersListResponse = [],
  //   [USER_LIST + LOADING]: usersListLoading = false
  // } = useSelector((state) => state?.Crud);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(updateLeaderBoardFormSchema),
    defaultValues: initialValues
  });

  // const getUsers = () => {
  //   dispatch(getAction(API_URLS.GET_USERS, [], USER_LIST)).catch((e) => notify.error(e?.message));
  // };

  const getLeaderboardDetails = () => {
    dispatch(
      getAction(API_URLS.GET_LEADERBOARD_DETAILS.replace(':id', id), {}, LEADERBOARD_DETAILS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    // getUsers();
    getLeaderboardDetails();
  }, []);

  useEffect(() => {
    if (leaderboardResponse) {
      const updatedDetails = {
        ...leaderboardResponse,
        iconId: leaderboardResponse?.photo?.id || '',
        users: leaderboardResponse?.users?.map((item) => {
          return { label: `${item?.firstName} ${item?.lastName || ''}`?.trim(), value: item?.id };
        })
      };
      reset(updatedDetails);
      setselectedImage(leaderboardResponse?.iconUrl);
    }
  }, [leaderboardResponse]);

  const handleImageChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setselectedImage(event.target.files[0]);
  };

  const handleImageUpload = async (leaderboard_id) => {
    try {
      const formData = new FormData();
      formData.append('file', selectedImage);
      const { data } = await dispatch(
        postAction(
          API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'LEADERS_BOARD'),
          formData,
          {},
          ''
        )
      );
      dispatch(
        patchAction(
          API_URLS.UPDATE_LEADERBOARD.replace(':id', leaderboard_id),
          {
            iconId: data.id
          },
          {},
          UPDATE_LEADERBOARD
        )
      );
    } catch (error) {
      console.error('Error handling image uploads:', error);
    }
  };

  const handleFormSubmit = async (data) => {
    dispatch(
      patchAction(API_URLS.UPDATE_LEADERBOARD.replace(':id', id), data, {}, UPDATE_LEADERBOARD)
    )
      .then(({ data }) => {
        if (typeof selectedImage !== 'string') handleImageUpload(data.id);
        notify.success('Successfully updated');
        reset(initialValues);
        navigate('/leaderboardListing/');
      })
      .catch((e) => notify.error(e?.message));
  };

  return (
    <Container>
      <SimpleCard title="Update Leaderboard">
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <ImageUpload handleImageChange={handleImageChange} selectedImage={selectedImage} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="name"
                variant="outlined"
                name="name"
                label="Name"
                placeholder="Type here"
                type="text"
                disabled={leaderboardLoading || leaderboardUpdating}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} lg={3} sx={{ ml: { lg: 2 } }}>
              <FormSwitch
                id="isPublic"
                variant="outlined"
                name="isPublic"
                label="Visibility"
                switchLabel="Public"
                disabled={leaderboardLoading || leaderboardUpdating}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} lg={12}>
              <FormInput
                id="description"
                variant="outlined"
                name="description"
                label="Description"
                placeholder="Type here"
                type="text"
                disabled={leaderboardLoading || leaderboardUpdating}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                multiline={true}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} lg={4}>
              <FormMultiSearchableDropdown
                id={'users'}
                variant="outlined"
                name={'users'}
                label="Users"
                placeholder="Select users"
                menuItems={normalizeDropdownList(usersListResponse?.users) || []}
                disabled={leaderboardLoading || leaderboardUpdating || usersListLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                fullWidth={true}
                onChange={(_, data) => {
                  setValue('users', data, { shouldValidate: true });
                }}
              />
            </Grid> */}
          </Grid>

          <LoadingButton
            type="submit"
            color="primary"
            loading={leaderboardUpdating || leaderboardLoading}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default UpdateLeaderboard;
