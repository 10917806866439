import React from 'react';
import { MenuItem, Select, FormControl } from '@mui/material';
import SearchInput from 'components/shared/Table/SearchInput/SearchInput';

const UserFilters = ({ handleStatusChange, status, onSearch, search, loading }) => {
  return (
    <div
      style={{
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'right'
      }}
    >
      <SearchInput
        placeholder={'Search'}
        onSearch={onSearch}
        value={search}
        width={{ xs: 'max-content', md: '460px' }}
        matchDownLG={true}
        loading={loading}
      />

      <FormControl variant="outlined" size="small" style={{ width: '200px' }}>
        <Select
          placeholder="Select Status"
          displayEmpty
          renderValue={status !== '' ? undefined : () => 'Select Status'}
          value={status}
          onChange={(e) => handleStatusChange(e.target.value)}
        >
          <MenuItem value={null}>All</MenuItem>
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default UserFilters;
