import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { workoutFormSchema } from 'lib/validation/workout';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { Fragment, useState } from 'react';
import { WorkoutInstructionsForm } from './WorkoutInstructionsForm';
import { getAction } from '../../store/actions/CRUDAction';
import { useEffect } from 'react';
import FormDropdown from 'components/shared/Forms/FormDropdown';
import { useNavigate } from 'react-router-dom';
import ImageUpload from 'components/ImageUpload';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const defaultVariationValues = {
  reccomendation: '',
  reccomendationDuration: 0,
  recommenedReps: 0,
  vahanaScore: 0,
  complexity: null,
  caloriePerMinute: 0,
  duration: '',
  sets: '',
  instructions: [
    {
      detail: '',
      order: 1
    }
  ]
};

const initialValues = {
  title: '',
  about: '',
  variations: [defaultVariationValues]
};

const { CREATE_WORKOUT, TARGET_AREAS_LIST, FITNESS_GOALS_LIST, LOADING, RESPONSE, UPDATE_WORKOUT } =
  REDUX_STATES;

const normalizeDropdownList = (data) => {
  return data?.map((item) => {
    return {
      label: item?.title,
      value: item?.id
    };
  });
};

const complexities = [
  { label: 'Beginner', value: 0 },
  { label: 'Intermediate', value: 1 },
  { label: 'Expert', value: 2 }
];

const formatUid = (text) => {
  return text.replace(/[\s/]/g, '_');
};

export const CreateWorkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImage, setselectedImage] = useState([]);
  const [selectedIcon, setselectedIcon] = useState(null);
  const [open, setOpen] = useState(false);

  // Redux State
  const { [CREATE_WORKOUT + LOADING]: postLoading = false } = useSelector((state) => state?.Crud);

  // Redux State
  const {
    [TARGET_AREAS_LIST + RESPONSE]: targetAreasResponse = [],
    [TARGET_AREAS_LIST + LOADING]: targetAreasLoading = false
  } = useSelector((state) => state?.Crud);

  const getTargetAreas = () => {
    dispatch(getAction(API_URLS.GET_TARGET_AREAS, [], TARGET_AREAS_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  const {
    [FITNESS_GOALS_LIST + RESPONSE]: fitnessGoalsResponse = [],
    [FITNESS_GOALS_LIST + LOADING]: fitnessGoalsLoading = false
  } = useSelector((state) => state?.Crud);

  const getFitnessGoals = () => {
    dispatch(getAction(API_URLS.GET_FITNESS_GOALS, [], FITNESS_GOALS_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getTargetAreas();
    getFitnessGoals();
  }, []);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(workoutFormSchema),
    defaultValues: initialValues
  });

  const { fields: variationsFields } = useFieldArray({
    control,
    name: 'variations'
  });

  const normalizeVariations = (variations) => {
    return variations?.map((variation) => {
      return {
        ...variation,
        complexity: complexities?.find((item) => item.value === variation?.complexity)?.label,
        instructions: variation?.instructions?.map((item, idx) => {
          return { ...item, order: idx + 1 };
        })
      };
    });
  };

  const handleImageUpload = async (workout_id) => {
    try {
      selectedImage.map(async (image) => {
        const formData = new FormData();
        formData.append('file', image);
        const { data } = dispatch(
          postAction(
            API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'EXERCISES') +
              `?workout_id=${workout_id}`,
            formData,
            {},
            ''
          )
        );
        return data;
      });
    } catch (error) {
      console.error('Error handling image uploads:', error);
    }
  };

  const handleCreateWorkout = async (values, photoId = '') => {
    const payload = {
      ...values,
      uid: formatUid(values?.title), //TODO: Should be handled at BE
      variations: normalizeVariations(values?.variations),
      images: [],
      photoId: photoId || ''
    };

    dispatch(postAction(API_URLS.CREATE_WORKOUT, payload, {}, CREATE_WORKOUT))
      .then(({ data }) => {
        selectedImage && handleImageUpload(data.id); // for workout images
        notify.success('Successfully created');
        reset(initialValues);
        navigate('/workoutListing/');
      })
      .catch((e) => notify.error(e?.message));
  };

  const handleFormSubmit = async (values) => {
    // for workout icon
    if (selectedIcon) {
      const formData = new FormData();
      formData.append('file', selectedIcon);
      dispatch(
        postAction(API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'WORKOUTS'), formData, {}, '')
      )
        .then(({ data }) => {
          handleCreateWorkout(values, data?.id);
        })
        .catch((e) => notify.error(e?.message));
    } else {
      handleCreateWorkout(values);
    }
  };

  const handleIconChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setselectedIcon(event.target.files[0]);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const largeFileFound = files?.find((file) => file?.size > ALLOWED_IMG_SIZE);
    if (largeFileFound) setOpen(true);
    else setselectedImage([...selectedImage, ...files]);
  };

  const removeImage = (indexToRemove) => {
    setselectedImage((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
  };

  return (
    <Container>
      <SimpleCard title="Create Workout">
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <ImageUpload
            handleImageChange={handleIconChange}
            selectedImage={selectedIcon}
            message="Upload Workout Icon"
          />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormInput
                id="title"
                variant="outlined"
                name="title"
                label="Title"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <FormDropdown
                id={'fitnessGoalId'}
                variant="outlined"
                name={'fitnessGoalId'}
                label="Fitness Goal"
                placeholder="Select fitness goal"
                menuItems={normalizeDropdownList(fitnessGoalsResponse)}
                disabled={postLoading || fitnessGoalsLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <FormDropdown
                id={'targetAreaId'}
                variant="outlined"
                name={'targetAreaId'}
                label="Target Area"
                placeholder="Select target area"
                menuItems={normalizeDropdownList(targetAreasResponse)}
                disabled={postLoading || targetAreasLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={12}>
              <FormInput
                id="about"
                variant="outlined"
                name="about"
                label="About"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                multiline={true}
              />
            </Grid>
          </Grid>

          <Typography variant="subtitle1" sx={{ pb: 1, pt: 2, fontWeight: 500 }}>
            Variations:
          </Typography>
          {variationsFields.map((item, index) => {
            return (
              <Fragment key={index}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`reccomendation`}
                      variant="outlined"
                      name={`variations[${index}].reccomendation`}
                      label="Recommendation"
                      placeholder="Type here"
                      type="text"
                      disabled={postLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`recommenedReps`}
                      variant="outlined"
                      name={`variations[${index}].recommenedReps`}
                      label="Recommended Reps"
                      placeholder="Type here"
                      type="number"
                      disabled={postLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`reccomendationDuration`}
                      variant="outlined"
                      name={`variations[${index}].reccomendationDuration`}
                      label="Recommended Duration"
                      placeholder="Type here"
                      type="number"
                      disabled={postLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`vahanaScore`}
                      variant="outlined"
                      name={`variations[${index}].vahanaScore`}
                      label="Vahana Score"
                      placeholder="Type here"
                      type="number"
                      disabled={postLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormInput
                      id={`sets`}
                      variant="outlined"
                      name={`variations[${index}].sets`}
                      label="Sets"
                      placeholder="Type here"
                      type="text"
                      disabled={postLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <FormDropdown
                      id={`complexity`}
                      variant="outlined"
                      name={`variations[${index}].complexity`}
                      label="Complexity"
                      placeholder="Select Complexity"
                      disabled={postLoading}
                      control={control}
                      errors={errors?.variations?.[index]}
                      touched={touched}
                      onBlur={handleBlur}
                      menuItems={complexities}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <WorkoutInstructionsForm
                      control={control}
                      errors={errors?.variations?.[index]}
                      name={`variations[${index}].instructions`}
                      loading={postLoading}
                      touched={touched}
                      handleBlur={handleBlur}
                      setValue={setValue}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            );
          })}

          <ImageUpload
            handleImageChange={handleImageChange}
            disabled={selectedImage.length === 4}
            minImages={2}
            removeImage={removeImage}
            selectedImage={selectedImage}
            message="Upload Workout Images"
            width={200}
            height={150}
          />

          <LoadingButton
            type="submit"
            color="primary"
            loading={postLoading}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default CreateWorkout;
