import { Controller } from 'react-hook-form';
import Input from '../InputFields/Input';

export default function FormInput({ control, name, id, errors, touched, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          onWheel={(e) => {
            e.target.blur();
          }}
          style={{ borderRadius: '8px', color: '#2D3748' }}
          {...props}
          {...field}
          error={Boolean(errors?.[id || name]?.message || touched?.[id || name]?.message)}
          helperText={errors?.[id || name]?.message || touched?.[id || name]?.message}
        />
      )}
    />
  );
}
