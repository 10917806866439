import React from 'react';
import { FormControl, Button, TextField } from '@mui/material';
import SearchInput from 'components/shared/Table/SearchInput/SearchInput';
import { Link } from 'react-router-dom';

const WorkoutFilters = ({ handleCreatedAtChange, createdAt, onSearch, search, loading }) => {
  return (
    <div
      style={{
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'right'
      }}
    >
      <SearchInput
        placeholder={'Search'}
        onSearch={onSearch}
        value={search}
        width={{ xs: 'max-content', md: '460px' }}
        matchDownLG={true}
        loading={loading}
      />

      {/* <FormControl variant="outlined" size="small" style={{ width: '200px' }}>
        <TextField
          displayEmpty
          size="small"
          type="date"
          name="createdAt"
          variant="outlined"
          value={createdAt}
          onChange={(e) => handleCreatedAtChange(e.target.value)}
          placeholder="Created At"
        />
      </FormControl> */}

      <Link to="/createWorkout">
        <Button color="primary" variant="contained">
          Create
        </Button>
      </Link>
    </div>
  );
};

export default WorkoutFilters;
