import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { getAction, postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import FormMultiSearchableDropdown from 'components/shared/Forms/FormMultiSearchableDropdown';
import FormDropdown from 'components/shared/Forms/FormDropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import { badgesFormSchema } from 'lib/validation/badges';
import ImageUpload from 'components/ImageUpload';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const normalizeDropdownList = (data) => {
  return data?.map((item) => {
    return {
      label: item?.title,
      value: item?.id
    };
  });
};

const exerciseDefaultValues = {
  goal: null,
  count: ''
};

const initialValues = {
  name: '',
  iconId: '',
  description: '',
  exercise: [exerciseDefaultValues],
  badge: []
};

const { CREATE_ACHIEVEMENT, FITNESS_GOALS_LIST, LOADING, GET_ALL_ACHIEVEMENTS, RESPONSE } =
  REDUX_STATES;

export const CreateAchievement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImage, setselectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  // Redux State
  const { [CREATE_ACHIEVEMENT + LOADING]: postLoading = false } = useSelector(
    (state) => state?.Crud
  );

  // Redux State
  const {
    [GET_ALL_ACHIEVEMENTS + RESPONSE]: badgesListResponse = [],
    [GET_ALL_ACHIEVEMENTS + LOADING]: badgesListLoading = false
  } = useSelector((state) => state?.Crud);

  const {
    [FITNESS_GOALS_LIST + RESPONSE]: fitnessGoalsResponse = [],
    [FITNESS_GOALS_LIST + LOADING]: fitnessGoalsLoading = false
  } = useSelector((state) => state?.Crud);

  const getFitnessGoals = () => {
    dispatch(getAction(API_URLS.GET_FITNESS_GOALS, [], FITNESS_GOALS_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  const getAchievements = () => {
    dispatch(getAction(API_URLS.GET_ALL_ACHIEVEMENTS, [], GET_ALL_ACHIEVEMENTS)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getFitnessGoals();
    getAchievements();
  }, []);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(badgesFormSchema),
    defaultValues: initialValues
  });

  const {
    fields: exerciseFields,
    append: exerciseAppend,
    remove: exerciseRemove
  } = useFieldArray({
    control,
    name: 'exercise'
  });

  const getGoalName = (goalId) => {
    return fitnessGoalsResponse?.find((item) => item?.id === goalId)?.title;
  };

  const formatDescription = (badges, exercises) => {
    // TODO: This should have been handle on BE
    const exerciseTemplate = 'Complete {count} {goal} exercises';
    const badgeTemplate = '{badge} Badge';

    const descriptions = exercises?.map((item) =>
      exerciseTemplate?.replace('{count}', item?.count)?.replace('{goal}', item?.goal)
    );

    descriptions?.concat(badges?.map((item) => badgeTemplate?.replace('{badge}', item)));
    return descriptions?.join('\n');
  };

  const handleCreateBadge = async (values, data) => {
    const selectedBadges = values?.badge?.map((item) => item?.label) || [];
    const selectedExercises = values?.exercise?.map((item) => {
      return { goal: getGoalName(item?.goal), count: item?.count };
    });

    const payload = {
      ...values,
      badge: selectedBadges,
      exercise: selectedExercises,
      description: formatDescription(selectedBadges, selectedExercises),
      iconId: data?.id || ''
    };

    dispatch(postAction(API_URLS.CREATE_ACHIEVEMENT, payload, {}, CREATE_ACHIEVEMENT))
      .then(() => {
        notify.success('Successfully created');
        reset(initialValues);
        navigate('/achievementListing/');
      })
      .catch((e) => notify.error(e?.response?.data?.error || e?.message));
  };

  const handleImageUpload = async (values) => {
    const formData = new FormData();
    formData.append('file', selectedImage);
    dispatch(
      postAction(API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'ACHIEVEMENTS'), formData, {}, '')
    )
      .then(({ data }) => {
        handleCreateBadge(values, data);
      })
      .catch((e) => notify.error(e?.message));
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setselectedImage(event.target.files[0]);
  };

  const handleFormSubmit = async (data) => {
    await handleImageUpload(data);
  };

  return (
    <Container>
      <SimpleCard title="Create Badge">
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <ImageUpload handleImageChange={handleImageChange} selectedImage={selectedImage} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <FormInput
                id="name"
                variant="outlined"
                name="name"
                label="Badge Name"
                placeholder="Type here"
                type="text"
                disabled={postLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="subtitle1" sx={{ pt: 2, fontWeight: 500 }}>
                Achievement Criteria
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} sx={{ pt: '0px !important' }}>
              <FormMultiSearchableDropdown
                id={'badge'}
                variant="outlined"
                name={'badge'}
                label="Badges"
                placeholder="Select badges"
                menuItems={normalizeDropdownList(badgesListResponse) || []}
                disabled={postLoading || badgesListLoading}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                fullWidth={true}
                onChange={(_, data) => {
                  setValue('badge', data, { shouldValidate: true });
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="subtitle2" sx={{ pt: 2, fontWeight: 500 }}>
                Exercises
              </Typography>
            </Grid>

            {exerciseFields.map((item, index) => {
              return (
                <Fragment key={item?.id}>
                  <Grid container spacing={2} sx={{ pl: 2 }}>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormDropdown
                        id={`goal`}
                        variant="outlined"
                        name={`exercise[${index}].goal`}
                        label="Fitness Goal"
                        placeholder="Select fitness goal"
                        menuItems={normalizeDropdownList(fitnessGoalsResponse)}
                        disabled={postLoading || fitnessGoalsLoading}
                        control={control}
                        errors={errors?.exercise?.[index]}
                        touched={touched}
                        onBlur={handleBlur}
                        fullWidth={true}
                        onChange={(_, data) => {
                          setValue(`exercise[${index}].goal`, data, { shouldValidate: true });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormInput
                        id="count"
                        variant="outlined"
                        name={`exercise[${index}].count`}
                        label="Count"
                        placeholder="Type here"
                        type="text"
                        disabled={postLoading}
                        control={control}
                        errors={errors?.exercise?.[index]}
                        touched={touched}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {exerciseFields?.length > 1 ? (
                      <Grid
                        item
                        xs={0.5}
                        sm={0.5}
                        lg={0.5}
                        sx={{ justifyContent: 'flex-end', alignSelf: 'center', mt: 3 }}
                      >
                        <DeleteIcon
                          sx={{ fontSize: '20px', cursor: 'pointer', float: 'right' }}
                          onClick={() => {
                            exerciseRemove(index);
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Fragment>
              );
            })}

            <Grid item xs={12} sm={6} lg={4} sx={{ float: 'right', pt: '0px !important' }}>
              <Button
                onClick={() => {
                  exerciseAppend(exerciseDefaultValues);
                }}
              >
                + Add more exercises
              </Button>
            </Grid>
          </Grid>

          <LoadingButton
            type="submit"
            color="primary"
            loading={postLoading}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default CreateAchievement;
