import { CardHeader, Icon, IconButton, MenuItem, Select, Switch, styled } from '@mui/material';
import { MatxLoading, SimpleCard } from 'components';
import PaginationTable from 'components/shared/Table/PaginationTable';
import { useState } from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import { REDUX_STATES } from '../../utils/constants/reduxStates';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAction } from '../../store/actions/CRUDAction';
import { API_URLS } from '../../utils/constants/apiUrls';
import { useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants/constant';
import { UserStatus } from './UserStatus';
import UserFilters from './UserFilters';
import { useDebounce } from 'contexts/hooks/useDebounce';
import { emptyCheck } from 'utils/utils';
import { notify } from 'components/shared/Notify/notify';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const tableHeader = [
  { label: 'First Name', id: 'firstName' },
  { label: 'Last Name', id: 'lastName' },
  { label: 'Email', id: 'email' },
  { label: 'Status', id: 'statusNode' },
  { label: 'Action', id: 'actionNode', align: 'center' }
];

const { USER_LIST, RESPONSE, ERROR, LOADING } = REDUX_STATES;

const UserListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatusChange] = useState('');
  const [userStatusChanged, setUserStatusChanged] = useState(false);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const debouncedSearchQuery = useDebounce(search, 600);

  const defaultUserParams = {
    page: page + 1,
    limit: rowsPerPage,
    isActivated: emptyCheck(status),
    search: emptyCheck(debouncedSearchQuery)
  };

  // Redux State
  const { [USER_LIST + RESPONSE]: userResponse = {}, [USER_LIST + LOADING]: userLoading = false } =
    useSelector((state) => state?.Crud);

  const getUsers = (params) => {
    dispatch(getAction(API_URLS.GET_USERS, { params }, USER_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getUsers(defaultUserParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, status, debouncedSearchQuery, userStatusChanged]);

  const normalizeTableData = (tableData) => {
    return tableData?.map((item) => {
      return {
        ...item,
        statusNode: (
          <UserStatus
            id={item?.id}
            isActivated={item?.isActivated}
            setUserStatusChanged={setUserStatusChanged}
            userStatusChanged={userStatusChanged}
          />
        ),
        actionNode: (
          <IconButton component={Link} to={`${item?.id}`} key={item?.id}>
            <Icon>keyboard_arrow_right</Icon>
          </IconButton>
        )
      };
    });
  };

  return (
    <Container>
      <SimpleCard title="Users" titleMargin={'0px'}>
        <UserFilters
          handleStatusChange={setStatusChange}
          status={status}
          onSearch={setSearch}
          search={search}
          loading={userLoading}
        />
        <MatxLoading isLoading={userLoading} />

        <PaginationTable
          data={normalizeTableData(userResponse?.users || [])}
          totalCount={userResponse?.totalCount}
          tableHeader={tableHeader}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default UserListing;
