import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'views/material-kit/MaterialRoutes';
import WorkoutListing from './views/workoutManagement/workoutListing';
import UserListing from './views/userManagement/UserListing';
import { UserDetail } from './views/userManagement/UserDetail';
import { WorkoutDetail } from './views/workoutManagement/WorkoutDetail';
import { UserProfile } from './views/userProfile/UserProfile';
import CreateWorkout from 'views/workoutManagement/CreateWorkout';
import UpdateWorkout from 'views/workoutManagement/UpdateWorkout';
import GoalsListing from 'views/goalsManagement/goalsListing';
import { GoalDetail } from 'views/goalsManagement/GoalDetail';
import UpdateGoal from 'views/goalsManagement/UpdateGoal';
import CreateGoal from 'views/goalsManagement/CreateGoal';
import LeaderBoardListing from 'views/leaderboardManagement/leaderboardListing';
import UpdateLeaderboard from 'views/leaderboardManagement/UpdateLeaderboard';
import CreateLeaderboard from 'views/leaderboardManagement/CreateLeaderboard';
import AchievementsListing from 'views/achievements/achievementsListing';
import { LeaderboardDetail } from 'views/leaderboardManagement/LeaderboardDetail';
import { AchievementDetail } from 'views/achievements/AchievementDetail';
import CreateAchievement from 'views/achievements/CreateAchievement';
import UpdateAchievement from 'views/achievements/UpdateAchievement';
import EntityLimitsListing, { ConfigureLimits } from 'views/configurations/entityLimitsListing';
import UpdateLimits from 'views/configurations/UpdateLimits';

// session pages
const NotFound = Loadable(lazy(() => import('views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('views/sessions/ForgotPassword')));

// dashboard page
const Analytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      {
        path: '/dashboard/',
        element: <Analytics />,
        auth: authRoles.admin
      },
      {
        path: '/userListing',
        element: <UserListing />,
        auth: authRoles.editor
      },
      {
        path: '/profile',
        element: <UserProfile />,
        auth: authRoles.editor
      },
      {
        path: `/userListing/:id`,
        element: <UserDetail />,
        auth: authRoles.editor
      },
      {
        path: '/workoutListing',
        element: <WorkoutListing />,
        auth: authRoles.editor
      },

      {
        path: '/workoutListing/:id',
        element: <WorkoutDetail />,
        auth: authRoles.editor
      },
      {
        path: '/workoutListing/update/:id',
        element: <UpdateWorkout />,
        auth: authRoles.editor
      },
      {
        path: '/createWorkout',
        element: <CreateWorkout />,
        auth: authRoles.editor
      },
      {
        path: '/goalsListing',
        element: <GoalsListing />,
        auth: authRoles.editor
      },
      {
        path: '/goalsListing/:id',
        element: <GoalDetail />,
        auth: authRoles.editor
      },
      {
        path: '/goalsListing/update/:id',
        element: <UpdateGoal />,
        auth: authRoles.editor
      },
      {
        path: '/createFitnessGoal',
        element: <CreateGoal />,
        auth: authRoles.editor
      },
      {
        path: '/leaderboardListing',
        element: <LeaderBoardListing />,
        auth: authRoles.editor
      },
      {
        path: '/leaderboardListing/:id',
        element: <LeaderboardDetail />,
        auth: authRoles.editor
      },
      {
        path: '/leaderboardListing/update/:id',
        element: <UpdateLeaderboard />,
        auth: authRoles.editor
      },
      {
        path: '/createLeaderboard',
        element: <CreateLeaderboard />,
        auth: authRoles.editor
      },
      {
        path: '/achievementListing',
        element: <AchievementsListing />,
        auth: authRoles.editor
      },
      {
        path: '/achievementListing/:id',
        element: <AchievementDetail />,
        auth: authRoles.editor
      },
      {
        path: '/achievementListing/update/:id',
        element: <UpdateAchievement />,
        auth: authRoles.editor
      },
      {
        path: '/createAchievement',
        element: <CreateAchievement />,
        auth: authRoles.editor
      },
      {
        path: '/configurations',
        element: <EntityLimitsListing />,
        auth: authRoles.editor
      },
      {
        path: '/configurations/update/:id',
        element: <UpdateLimits />,
        auth: authRoles.editor
      }
    ]
  },

  // session pages route
  { path: '/404', element: <NotFound /> },
  { path: '/signin', element: <JwtLogin /> },
  { path: '/signup', element: <JwtRegister /> },
  { path: '/forgot-password', element: <ForgotPassword /> },

  { path: '/', element: <Navigate to="dashboard/" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
