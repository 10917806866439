export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const API_TIMEOUT = 90000;
export const ALLOWED_IMG_SIZE = 1048576;

export const Date = {
  MM_DD_YYYY: 'MM-dd-yyyy'
};

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  USER_INFO: 'USER_INFO'
};

export const API_PARAMS = {
  PAGE_SIZE: 'pageSize',
  LIMIT: 'limit'
};
