import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { getAction, patchAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import FormDropdown from 'components/shared/Forms/FormDropdown';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { configureLimitsSchema } from 'lib/validation/configureLimits';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const initialValues = {
  entityName: '',
  entityLimit: null
};

const { UPDATE_ENTITY_LIMIT_SETTINGS, ENTITY_LIMIT_DETAILS, LOADING, RESPONSE } = REDUX_STATES;

export const UpdateLimits = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  // Redux State
  const { [UPDATE_ENTITY_LIMIT_SETTINGS + LOADING]: updatingLimit = false } = useSelector(
    (state) => state?.Crud
  );

  const {
    [ENTITY_LIMIT_DETAILS + RESPONSE]: entityResponse = [],
    [ENTITY_LIMIT_DETAILS + LOADING]: entityLoading = false
  } = useSelector((state) => state?.Crud);

  const getEntitySettings = () => {
    dispatch(
      getAction(API_URLS.GET_ENTITY_LIMIT.replace(':id', id), [], ENTITY_LIMIT_DETAILS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    getEntitySettings();
  }, []);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    reset
  } = useForm({
    resolver: yupResolver(configureLimitsSchema),
    defaultValues: initialValues
  });

  useEffect(() => {
    if (entityResponse) {
      const updatedDetails = {
        entityName: entityResponse?.entityName,
        entityLimit: entityResponse?.entityLimit
      };
      reset(updatedDetails);
    }
  }, [entityResponse]);

  const handleFormSubmit = async (data) => {
    const payload = {
      entityLimit: data?.entityLimit
    };
    dispatch(
      patchAction(
        API_URLS.UPDATE_ENTITY_LIMIT.replace(':id', id),
        payload,
        {},
        UPDATE_ENTITY_LIMIT_SETTINGS
      )
    )
      .then(() => {
        reset(initialValues);
        notify.success('Successfully updated');
        navigate('/configurations');
      })
      .catch((e) => notify.error(e?.response?.data?.message || e?.message));
  };

  return (
    <Container>
      <SimpleCard title="Configure Limits" subtitle={'Set creation limit for the selected entity'}>
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <FormInput
                id="entityName"
                variant="outlined"
                name={'entityName'}
                label="Entity Name"
                type="text"
                control={control}
                errors={errors?.entityName}
                touched={touched}
                onBlur={handleBlur}
                inputProps={{
                  readOnly: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <FormInput
                id="entityLimit"
                variant="outlined"
                name={'entityLimit'}
                label="Limit"
                placeholder="Type here"
                type="text"
                disabled={updatingLimit || entityLoading}
                control={control}
                errors={errors?.entityLimit}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <LoadingButton
            type="submit"
            color="primary"
            loading={updatingLimit}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default UpdateLimits;
