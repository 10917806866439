/*
  Boot component for application.
*/

import React from 'react';

import { Provider } from 'react-redux';
// import { createBrowserHistory } from 'history';

import App from './App';
import configureStore from './store/configureStore';

// Components
// import storageService from './services/storageService/StorageService';

// Storage Devices

// Stores & History
const store = configureStore();
// const history = createBrowserHistory();

function BootSetup() {
  // Save Platform Web
  //   storageService.instance = new StorageService(localStorage);

  return (
    <>
      <Provider store={store}>
        <App />
      </Provider>
    </>
  );
}

export default BootSetup;
