/* 
  Service file that serve all api calling
*/

import axios from 'axios';
import StorageService from '../storageService/StorageService';

// Helper
import { API_TIMEOUT } from '../../utils/constants/constant';

export const apiClient = () => {
  const baseURL = process.env.REACT_APP_PUBLIC_API_URL;
  const token = StorageService.getAccessToken();

  // Dummy Condition, will remove with actual api
  let defaultOptions = !!token
    ? {
        headers: {
          Authorization: token ? `Bearer ${token || null}` : '',
          'Content-Type': 'application/json',
          'X-ApplicationIDHeader': 2
        }
      }
    : {
        headers: {
          'Content-Type': 'application/json'
        }
      };

  // Request timeout
  defaultOptions = {
    ...defaultOptions,
    timeout: API_TIMEOUT
  };

  // for File change formData to multipart
  const makeRequest = (method, url, data, options = {}) => {
    const contentType = data instanceof FormData ? 'multipart/form-data' : 'application/json';
    const requestData = data instanceof FormData ? data : JSON.stringify(data);
    const headers = {
      ...defaultOptions.headers,
      'Content-Type': contentType
    };
    return axios[method](`${baseURL}${url}`, requestData, {
      ...defaultOptions,
      ...options,
      headers
    });
  };

  return {
    get: (url, options = {}) => axios.get(`${baseURL}${url}`, { ...defaultOptions, ...options }),
    post: (url, data, options = {}) => makeRequest('post', url, data, options),
    put: (url, data, options = {}) => makeRequest('put', url, data, options),
    patch: (url, data, options = {}) => makeRequest('patch', url, data, options),
    delete: (url, data, options = {}) =>
      axios.delete(`${baseURL}${url}`, { ...defaultOptions, ...options, data })
  };
};
