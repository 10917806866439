import { Autocomplete, FormHelperText, InputLabel, TextField } from '@mui/material';
import * as React from 'react';
import { Controller } from 'react-hook-form';

export default function FormMultiSearchableDropdown({
  placeholder,
  menuItems,
  minWidth,
  label,
  name,
  errors,
  control,
  helperText,
  id,
  ...props
}) {
  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };

  const getOptionLabel = (option) =>
    option.label?.length ? option.label : placeholder || 'Select option';

  return (
    <>
      {label ? <InputLabel sx={{ mt: 1.5, color: '#2D3748' }}>{label}</InputLabel> : null}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              disableCloseOnSelect
              multiple
              fullWidth
              id={id}
              autoComplete={'off'}
              options={menuItems}
              limitTags={2}
              getOptionLabel={getOptionLabel}
              filterOptions={filterOptions}
              {...field}
              value={field.value || []}
              onChange={(_, data) => {
                return data;
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={id}
                  autoComplete={'off'}
                  variant="outlined"
                  placeholder={placeholder}
                  error={!!errors?.[id || name]?.message}
                  helperText={errors?.[id || name]?.message}
                  sx={{
                    mt: label ? 1 : 0,
                    '& .MuiOutlinedInput-root': {
                      p: 0.65,
                      '& .MuiInputBase-input': {
                        p: 1.15,
                      },
                      '& fieldset': {
                        borderRadius: 2,
                        borderColor: '#E2E8F0'
                      }
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                      backgroundColor: '#00000008'
                    }
                  }}
                />
              )}
              {...props}
            />
          );
        }}
      />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </>
  );
}
