import { LoadingButton } from '@mui/lab';
import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material';
import { SimpleCard } from 'components';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/shared/Forms/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { getAction, patchAction, postAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { notify } from 'components/shared/Notify/notify';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import FormMultiSearchableDropdown from 'components/shared/Forms/FormMultiSearchableDropdown';
import FormDropdown from 'components/shared/Forms/FormDropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import { badgesFormSchema, updateBadgesFormSchema } from 'lib/validation/badges';
import ImageUpload from 'components/ImageUpload';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { ALLOWED_IMG_SIZE } from 'utils/constants/constant';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const normalizeDropdownList = (data) => {
  return data?.map((item) => {
    return {
      label: item?.title,
      value: item?.id
    };
  });
};

const exerciseDefaultValues = {
  goal: '',
  id: 0,
  count: ''
};

const initialValues = {
  name: '',
  iconId: '',
  description: '',
  exercise: [exerciseDefaultValues],
  badge: []
};

const {
  UPDATE_ACHIEVEMENTS,
  FITNESS_GOALS_LIST,
  LOADING,
  ACHIEVEMENTS_LIST,
  RESPONSE,
  ERROR,
  ACHIEVEMENTS_DETAILS
} = REDUX_STATES;

export const UpdateAchievement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedImage, setselectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  // Redux State
  const {
    [UPDATE_ACHIEVEMENTS + ERROR]: updateError = false,
    [UPDATE_ACHIEVEMENTS + LOADING]: badgesUpdating = false
  } = useSelector((state) => state?.Crud);

  // Redux State
  // const {
  //   [ACHIEVEMENTS_LIST + RESPONSE]: badgesListResponse = [],
  //   [ACHIEVEMENTS_LIST + LOADING]: badgesListLoading = false
  // } = useSelector((state) => state?.Crud);

  const {
    [ACHIEVEMENTS_DETAILS + RESPONSE]: badgesResponse = [],
    [ACHIEVEMENTS_DETAILS + LOADING]: badgesLoading = false
  } = useSelector((state) => state?.Crud);

  const {
    [FITNESS_GOALS_LIST + RESPONSE]: fitnessGoalsResponse = [],
    [FITNESS_GOALS_LIST + LOADING]: fitnessGoalsLoading = false
  } = useSelector((state) => state?.Crud);

  const getFitnessGoals = () => {
    dispatch(getAction(API_URLS.GET_FITNESS_GOALS, [], FITNESS_GOALS_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  // const getAchievements = () => {
  //   dispatch(getAction(API_URLS.GET_ACHIEVEMENTS, [], ACHIEVEMENTS_LIST)).catch((e) =>
  //     notify.error(e?.message)
  //   );
  // };

  const getAchievementById = () => {
    dispatch(
      getAction(API_URLS.GET_ACHIEVEMENT_DETAILS.replace(':id', id), [], ACHIEVEMENTS_DETAILS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    getAchievementById();
    // getAchievements();
    getFitnessGoals();
  }, []);

  const {
    control,
    formState: { errors, touched },
    handleSubmit,
    handleBlur,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(updateBadgesFormSchema),
    defaultValues: initialValues
  });

  const {
    fields: exerciseFields
    // append: exerciseAppend,
    // remove: exerciseRemove
  } = useFieldArray({
    control,
    name: 'exercise'
  });

  // const getBadge = (value) => {
  //   const badge = badgesListResponse?.find((item) => item?.title === value);
  //   return { value: badge?.id, label: badge?.title };
  // };

  const getGoal = (value) => {
    const badge = fitnessGoalsResponse?.find((item) => item?.title === value);
    return badge?.id;
  };

  const getGoalName = (goalId) => {
    return fitnessGoalsResponse?.find((item) => item?.id === goalId)?.title;
  };

  useEffect(() => {
    if (badgesResponse) {
      const updatedDetails = {
        name: badgesResponse?.title,
        description: badgesResponse?.description,
        exercise: badgesResponse?.achievementCriteria
          ?.filter((item) => item?.criteria?.goal !== null)
          ?.map((item) => {
            return {
              goal: getGoal(item?.criteria?.goal),
              count: item?.criteria?.value,
              exerciseId: item?.criteria?.id
            };
          }),
        badge: badgesResponse?.achievementCriteria
          ?.filter((item) => item?.criteria?.goal === null)
          ?.map((item) => item?.criteria?.value),
        iconId: badgesResponse?.icon?.id
      };
      reset(updatedDetails);
      setselectedImage(badgesResponse?.iconUrl);
    }
  }, [badgesResponse]);

  const formatDescription = (badges, exercises) => {
    const exerciseTemplate = 'Complete {count} {goal} exercises';
    const badgeTemplate = '{badge} Badge';

    const descriptions = exercises?.map((item) =>
      exerciseTemplate?.replace('{count}', item?.count)?.replace('{goal}', item?.goal)
    );

    descriptions?.concat(badges?.map((item) => badgeTemplate?.replace('{badge}', item)));
    return descriptions?.join('\n');
  };

  const handleImageChange = (event) => {
    if (event.target.files[0]?.size > ALLOWED_IMG_SIZE) setOpen(true);
    else setselectedImage(event.target.files[0]);
  };

  const handleUpdateBadge = async (values, iconId = null) => {
    const exercises = values?.exercise?.map((item) => {
      return { goal: getGoalName(item?.goal), count: item?.count, id: item?.exerciseId };
    });

    const payload = {
      ...values,
      description: formatDescription(values?.badge, exercises),
      exercise: exercises,
      iconId: iconId || values?.iconId
    };

    dispatch(
      patchAction(API_URLS.UPDATE_ACHIEVEMENTS.replace(':id', id), payload, {}, UPDATE_ACHIEVEMENTS)
    )
      .then(() => {
        notify.success('Successfully created');
        reset(initialValues);
        navigate('/achievementListing/');
      })
      .catch((e) => notify.error(e?.response?.data?.error || e?.message));
  };

  const handleImageUpload = async (values) => {
    try {
      if (typeof selectedImage !== 'string') {
        const formData = new FormData();
        formData.append('file', selectedImage);
        const { data } = await dispatch(
          postAction(
            API_URLS.GET_UPLOADED_FILE_PATH.replace(':type', 'ACHIEVEMENTS'),
            formData,
            {},
            ''
          )
        );
        handleUpdateBadge(values, data?.id);
      } else {
        handleUpdateBadge(values);
      }
    } catch (e) {
      notify.error(e?.message);
    }
  };

  const handleFormSubmit = async (data) => {
    await handleImageUpload(data);
  };

  return (
    <Container>
      <SimpleCard title="Update Badge">
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <ImageUpload handleImageChange={handleImageChange} selectedImage={selectedImage} />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <FormInput
                id="name"
                variant="outlined"
                name="name"
                label="Name"
                placeholder="Type here"
                type="text"
                disabled={badgesUpdating}
                control={control}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                Exercises
              </Typography>
            </Grid>

            {exerciseFields.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Grid container spacing={2} sx={{ pl: 2, alignItems: 'center' }}>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormDropdown
                        id={`goal`}
                        variant="outlined"
                        name={`exercise[${index}].goal`}
                        label="Fitness Goal"
                        placeholder="Select fitness goal"
                        menuItems={normalizeDropdownList(fitnessGoalsResponse)}
                        disabled={badgesUpdating || fitnessGoalsLoading}
                        control={control}
                        errors={errors?.exercise?.[index]}
                        touched={touched}
                        onBlur={handleBlur}
                        fullWidth={true}
                        onChange={(_, data) => {
                          setValue(`exercise[${index}].goal`, data, { shouldValidate: true });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormInput
                        id="count"
                        variant="outlined"
                        name={`exercise[${index}].count`}
                        label="Count"
                        placeholder="Type here"
                        type="text"
                        disabled={badgesUpdating}
                        control={control}
                        errors={errors?.exercise?.[index]}
                        touched={touched}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    {/* {exerciseFields?.length > 1 ? (
                      <Grid
                        item
                        xs={0.5}
                        sm={0.5}
                        lg={0.5}
                        sx={{ justifyContent: 'flex-end', mt: 3 }}
                      >
                        <DeleteIcon
                          sx={{ fontSize: '20px', cursor: 'pointer', float: 'right' }}
                          onClick={() => {
                            exerciseRemove(index);
                          }}
                        />
                      </Grid>
                    ) : null} */}
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>

          <LoadingButton
            type="submit"
            color="primary"
            loading={badgesUpdating}
            variant="contained"
            sx={{ my: 2 }}
          >
            Submit
          </LoadingButton>
        </form>
      </SimpleCard>
      <AlertDialog
        open={open}
        title="Large Image Size!"
        content={'Images of upto 1MB are supported'}
        actions={
          <>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Ok
            </Button>
          </>
        }
        setOpen={setOpen}
      />
    </Container>
  );
};

export default UpdateAchievement;
