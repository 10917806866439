export const REDUX_STATES = {
  DEFAULT_KEY: 'listing' + new Date().getTime(),
  DEFAULT_DROPDOWN_KEY: 'dropdown' + new Date().getTime(),
  DEFAULT_POST_KEY: 'post' + new Date().getTime(),
  DEFAULT_PUT_KEY: 'put' + new Date().getTime(),
  DEFAULT_PATCH_KEY: 'patch' + new Date().getTime(),
  DEFAULT_DELETE_KEY: 'delete' + new Date().getTime(),
  DEFAULT_SELECTED_KEY: 'selected' + new Date().getTime(),
  LOADING: '_loading',
  ERROR: '_error',
  RESPONSE: '_response',

  //User management module
  USER_LIST: 'userListing',
  USER_DETAIL: 'userDetail',
  UPDATE_STATUS: 'updateStatus',

  //Dashboard stats
  TOTAL_USERS: 'totalUsers',
  ACTIVE_USERS: 'activeUsers',
  TRAFFIC_SOURCES: 'trafficSources',
  ANNUAL_USER_COUNT: 'annualUserCount',

  //Workouts management module
  WORKOUT_LIST: 'workoutList',
  WORKOUT_DETAIL: 'workoutDetail',
  TARGET_AREAS_LIST: 'targetAreasList',
  FITNESS_GOALS_LIST: 'fitnessGoalsList',
  CREATE_WORKOUT: 'createWorkout',
  UPDATE_WORKOUT: 'updateWorkout',

  //Goals management module
  GOALS_LIST: 'goalsList',
  GOAL_DETAILS: 'goalDetail',
  UPDATE_GOAL: 'updateGoal',
  CREATE_GOAL: 'createGoal',

  //Leaderboard management module
  LEADERBOARD_LIST: 'leadersboardList',
  LEADERBOARD_DETAILS: 'leaderboardDetail',
  CREATE_LEADERBOARD: 'createLeadersboard',
  UPDATE_LEADERBORD: 'updateLeadersboard',

  //Achievements and Badges
  ACHIEVEMENTS_LIST: 'badgesList',
  ACHIEVEMENTS_DETAILS: 'badgeDetail',
  CREATE_ACHIEVEMENT: 'createBadge',
  UPDATE_ACHIEVEMENTS: 'updateBadge',
  GET_ALL_ACHIEVEMENTS: 'allbadges',

  //Limit configs
  ENTITY_LIMIT_SETTINGS: 'entitySettings',
  UPDATE_ENTITY_LIMIT_SETTINGS: 'updateEntitySettings',
  ENTITY_LIMIT_DETAILS: 'entityLimitDetail'
};
