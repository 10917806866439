import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  }
}));

const PaginationTable = ({
  data,
  totalCount,
  tableHeader,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions
}) => {
  const defaultRowsPerPage = [5, 10, 25];

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            {tableHeader?.map(({ id, label, align }) => {
              return (
                <TableCell key={id} align={align ? align : 'left'}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length ? (
            data?.map((rowData, index) => (
              <TableRow key={index}>
                {tableHeader?.map(({ id, align }) => {
                  // render react node in table cell
                  return id === `${id}Node` ? (
                    <TableCell key={id} align={align ? align : 'left'}>
                      {rowData[`${id}Node`]}
                    </TableCell>
                  ) : (
                    // render data in table cell
                    <TableCell
                      key={id}
                      align={align ? align : 'left'}
                      style={{
                        wordBreak: 'break-word',
                        textTransform: id === 'title' ? 'capitalize' : 'none'
                      }}
                    >
                      {rowData?.[id] ? rowData[id] : ''}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={tableHeader?.length}>
                No record found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
      {data?.length ? (
        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={totalCount}
          onPageChange={handleChangePage}
          rowsPerPageOptions={rowsPerPageOptions?.length ? rowsPerPageOptions : defaultRowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </Box>
  );
};

export default PaginationTable;
