import * as yup from 'yup';

export const workoutFormSchema = yup.object().shape({
  variations: yup.array().of(
    yup.object().shape({
      recommenedReps: yup.number().required('Required'),
      vahanaScore: yup.number().required('Required'),
      // caloriePerMinute: yup.number().required('Required'),
      reccomendationDuration: yup.number().required('Required'),
      reccomendation: yup.string().required('Required'),
      sets: yup.string().required('Required'),
      // duration: yup.string().required('Required'),
      complexity: yup.number().required('Required'),
      instructions: yup.array().of(
        yup.object().shape({
          order: yup.number().required('Required'),
          detail: yup.string().required('Required')
        })
      )
    })
  ),
  title: yup.string().required('Required'),
  about: yup.string().required('Required'),
  fitnessGoalId: yup.number().required('Required'),
  targetAreaId: yup.number().required('Required'),
});


export const updateWorkoutFormSchema = yup.object().shape({
  variations: yup.array().of(
    yup.object().shape({
      recommenedReps: yup.number().required('Required'),
      vahanaScore: yup.number().required('Required'),
      // caloriePerMinute: yup.number().required('Required'),
      reccomendationDuration: yup.number().required('Required'),
      reccomendation: yup.string().required('Required'),
      sets: yup.string().required('Required'),
      // duration: yup.string().required('Required'),
      complexity: yup.number().required('Required'),
      instructions: yup.array().of(
        yup.object().shape({
          order: yup.number().required('Required'),
          detail: yup.string().required('Required')
        })
      )
    })
  ),
  title: yup.string().required('Required'),
  about: yup.string().required('Required'),
  targetAreaId: yup.number().required('Required')
});
