import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'components';
import Service from '../services/api/Service';
import { API_URLS } from '../utils/constants/apiUrls';
import { LOCAL_STORAGE_KEYS } from '../utils/constants/constant';
import { login, logout } from 'store/actions/AuthAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notify } from 'components/shared/Notify/notify';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  userLogin: () => {},
  userlogout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const defaultDispatch = useDispatch();

  const userLogin = async (credentials) => {
    defaultDispatch(login(credentials)).then(
      (user) => {
        dispatch({ type: 'LOGIN', payload: { user } });
        navigate('/dashboard/');
      },
      (e) => {
        console.log(e);
        notify.error(e?.message);
      }
    );
  };

  const register = async (email, username, password) => {
    const response = await Service.postService(API_URLS.REGISTER, {
      email,
      username,
      password
    });
    const { user } = response.data;

    dispatch({ type: 'REGISTER', payload: { user } });
  };

  const userlogout = () => {
    defaultDispatch(logout()).then(
      () => {
        dispatch({ type: 'LOGOUT' });
        navigate('/signin');
      },
      (e) => {
        console.log(e);
        alert('Error');
      }
    );
  };

  useEffect(() => {
    dispatch({
      type: 'INIT',
      payload: { isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) }
    });
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', userLogin, userlogout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
