import React from 'react';
import { Controller } from 'react-hook-form';
import Dropdown from '../InputFields/Dropdown';

export default function FormDropdown({ id, control, name, errors, menuItems, label, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Dropdown
          sx={{ color: '#2D3748' }}
          label={label}
          placeholder="Type here"
          id={name}
          autoComplete={name}
          menuItems={menuItems}
          {...props}
          {...field}
          error={Boolean(errors?.[id || name]?.message)}
          helperText={errors?.[id || name]?.message}
        />
      )}
    />
  );
}
