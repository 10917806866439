const { ThreeCircles } = require('react-loader-spinner');

const Loader = ({ loading, height, width }) => {
  return (
    <ThreeCircles
      height={height || '15'}
      width={width || '15'}
      color="#2E6EB3"
      wrapperStyle={{}}
      wrapperClass=""
      visible={loading}
      ariaLabel="three-circles-rotating"
      outerCircleColor=""
      innerCircleColor=""
      middleCircleColor=""
    />
  );
};

export default Loader;
