import { Button } from '@mui/material';
import AlertDialog from 'components/shared/AlertDialogue/AlertDialoge';
import { CustomIconButton } from 'components/shared/Button/CustomIconButton';
import Loader from 'components/shared/Loader/Loader';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { notify } from 'components/shared/Notify/notify';

const { DEFAULT_DELETE_KEY, LOADING } = REDUX_STATES;

export const DeleteLeaderboard = ({ id, setIsDeleted, isDeleted }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { [DEFAULT_DELETE_KEY + LOADING]: Loading = false } = useSelector((state) => state?.Crud);

  const handleLeaderboardDelete = () => {
    dispatch(
      deleteAction(API_URLS.DELETE_LEADERBOARD.replace(':id', id), { id: id }, {}, DEFAULT_DELETE_KEY)
    )
      .then(() => {
        notify.success('Successfully deleted');
        setIsDeleted(!isDeleted);
      })
      .catch((e) => notify.error(e?.message));
    setOpen(false);
  };

  return (
    <>
      <AlertDialog
        key={id}
        open={open}
        title="Confirmation"
        content={`Do you want to delete this leaderboard?`}
        actions={
          <>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                handleLeaderboardDelete();
              }}
            >
              Yes
            </Button>
          </>
        }
        setOpen={setOpen}
      />
      <Loader loading={Loading} />
      {Loading ? null : (
        <CustomIconButton
          key={'delete'}
          path={null}
          iconName="delete"
          onClick={() => setOpen(true)}
        />
      )}
    </>
  );
};
