import React from 'react';
import { SimpleCard } from '../../components';
import { Grid, Typography, styled } from '@mui/material';
import { Date, LOCAL_STORAGE_KEYS } from '../../utils/constants/constant';
import { timeZoneConverter } from '../../utils/utils';
import { format } from 'date-fns';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

export const UserProfile = () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));

  return (
    <Container>
      <SimpleCard title="User Profile">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} lg={4}>
            <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
              Fisrt Name
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {user?.firstName || ''}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} lg={4}>
            <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
              Last Name
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {user?.lastName || ''}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} lg={4}>
            <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
              Email
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {user?.email || ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} lg={4}>
            <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
              Created At
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {user?.createdAt ? format(timeZoneConverter(user?.createdAt), Date.MM_DD_YYYY) : ''}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} lg={4}>
            <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
              Role
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {user?.role?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} lg={4}>
            <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
              Status
            </Typography>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {user?.status?.name}
            </Typography>
          </Grid>
        </Grid>
      </SimpleCard>
    </Container>
  );
};
