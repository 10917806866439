import { styled, Button } from '@mui/material';
import { MatxLoading, SimpleCard } from 'components';
import { notify } from 'components/shared/Notify/notify';
import PaginationTable from 'components/shared/Table/PaginationTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from 'store/actions/CRUDAction';
import { API_URLS } from 'utils/constants/apiUrls';
import { REDUX_STATES } from 'utils/constants/reduxStates';
import { emptyCheck, timeZoneConverter } from 'utils/utils';
import { CustomIconButton } from 'components/shared/Button/CustomIconButton';
import { format } from 'date-fns';
import { Date } from 'utils/constants/constant';
import { DeleteGoal } from './DeleteGoal';
import { Link } from 'react-router-dom';
import { useDebounce } from 'contexts/hooks/useDebounce';
import GoalFilters from './GoalFilters';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const tableHeader = [
  { label: 'Title', id: 'title' },
  { label: 'Description', id: 'description' },
  { label: 'Created At', id: 'created_at', align: 'center' },
  { label: 'Action', id: 'actionNode', align: 'center' }
];

const { GOALS_LIST, RESPONSE, LOADING, ENTITY_LIMIT_DETAILS } = REDUX_STATES;

const GoalsListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDeleted, setIsDeleted] = useState(false);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  const debouncedSearchQuery = useDebounce(search, 600);

  const defaultUserParams = {
    page: page + 1,
    limit: rowsPerPage,
    search: emptyCheck(debouncedSearchQuery)
  };

  // Redux State
  const {
    [GOALS_LIST + RESPONSE]: goalsResponse = [],
    [GOALS_LIST + LOADING]: goalsLoading = false
  } = useSelector((state) => state?.Crud);

  const { [ENTITY_LIMIT_DETAILS + RESPONSE]: entityResponse = [] } = useSelector(
    (state) => state?.Crud
  );

  const getGoals = (params) => {
    dispatch(getAction(API_URLS.GET_GOALS, { params }, GOALS_LIST)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getGoals(defaultUserParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, isDeleted, debouncedSearchQuery]);

  const getEntitySettings = () => {
    dispatch(
      getAction(API_URLS.GET_ENTITY_LIMIT.replace(':id', '3'), [], ENTITY_LIMIT_DETAILS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    getEntitySettings();
  }, []);

  const normalizeTableData = (tableData) => {
    return tableData?.map((item) => {
      return {
        ...item,
        created_at: item?.created_at
          ? format(timeZoneConverter(item?.created_at), Date.MM_DD_YYYY)
          : null,
        actionNode: (
          <>
            <CustomIconButton id={'edit'} path={`update/${item?.id}`} iconName="edit" />
            <DeleteGoal
              key={'delete'}
              id={item?.id}
              setIsDeleted={setIsDeleted}
              isDeleted={isDeleted}
            />
            <CustomIconButton id={'view'} path={`${item?.id}`} iconName="keyboard_arrow_right" />
          </>
        )
      };
    });
  };

  return (
    <Container>
      <SimpleCard title="Fitness Goals" titleMargin={'0px'}>
        <GoalFilters
          onSearch={setSearch}
          search={search}
          loading={goalsLoading}
          disabled={goalsResponse?.totalCount >= entityResponse?.entityLimit}
          allowedLimit={entityResponse?.entityLimit}
        />

        <MatxLoading isLoading={goalsLoading} />

        <PaginationTable
          data={normalizeTableData(goalsResponse?.fitnessGoals || [])}
          totalCount={goalsResponse?.totalCount || 0}
          tableHeader={tableHeader}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default GoalsListing;
