import React from 'react';
import { SimpleCard } from '../../components';
import { Grid, Typography, styled } from '@mui/material';
import { REDUX_STATES } from '../../utils/constants/reduxStates';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../store/actions/CRUDAction';
import { API_URLS } from '../../utils/constants/apiUrls';
import { Date } from '../../utils/constants/constant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { timeZoneConverter } from '../../utils/utils';
import { format } from 'date-fns';
import Loader from 'components/shared/Loader/Loader';
import { notify } from 'components/shared/Notify/notify';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const { USER_DETAIL, RESPONSE, LOADING } = REDUX_STATES;

export const UserDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Redux State
  const {
    [USER_DETAIL + RESPONSE]: userResponse = {},
    [USER_DETAIL + LOADING]: userLoading = false
  } = useSelector((state) => state?.Crud);

  const getUserDetail = () => {
    dispatch(getAction(API_URLS.GET_USER_DETAIL.replace(':id', id), {}, USER_DETAIL)).catch((e) =>
      notify.error(e?.message)
    );
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <Container>
      {userLoading ? (
        <Loader loading={userLoading} />
      ) : (
        <SimpleCard title="User Detail">
          <SimpleCard
            sx={{
              pt: 1,
              pb: 2,
              px: 2,
              backgroundColor: '#f5f5f5',
              boxShadow: 'none !important',
              borderRadius: 0
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                  First Name
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {userResponse?.user?.firstName || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                  Last Name
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {userResponse?.user?.lastName || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                  Email
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {userResponse?.user?.email || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                  Gender
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {userResponse?.gender || ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                  Date Of Birth
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {userResponse?.dob || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                  Created At
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {userResponse?.user?.createdAt
                    ? format(timeZoneConverter(userResponse?.user?.createdAt), Date.MM_DD_YYYY)
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                  Status
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {userResponse?.user?.isActivated ? 'Active' : 'Inactive'}
                </Typography>
              </Grid>
            </Grid>
          </SimpleCard>

          <Grid container spacing={2} sx={{ pt: 1.5, pl: 1 }}>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Height
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.height || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Height Unit
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.heightUnit || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Weight
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.weight || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Weight Unit
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.weightUnit || ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 1 }}>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Plan Preference
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.planPreference || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Goal
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.goal || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Workout Level
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.workoutLevel || ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} lg={3}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Workout Type
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.workoutType || ''}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 1 }}>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                Bio
              </Typography>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {userResponse?.bio || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </SimpleCard>
      )}
    </Container>
  );
};
