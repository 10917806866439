import React from 'react';
import { Button } from '@mui/material';
import SearchInput from 'components/shared/Table/SearchInput/SearchInput';
import { Link } from 'react-router-dom';

const LeaderboardFilters = ({ onSearch, search, loading }) => {
  return (
    <div
      style={{
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'right'
      }}
    >
      <SearchInput
        placeholder={'Search'}
        onSearch={onSearch}
        value={search}
        width={{ xs: 'max-content', md: '460px' }}
        matchDownLG={true}
        loading={loading}
      />

      <Link to="/createLeaderboard">
        <Button color="primary" variant="contained">
          Create
        </Button>
      </Link>
    </div>
  );
};

export default LeaderboardFilters;
