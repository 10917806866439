import React from 'react';
import { Button, Tooltip, IconButton } from '@mui/material';
import SearchInput from 'components/shared/Table/SearchInput/SearchInput';
import { Link, useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AchievementFilters = ({ onSearch, search, loading, disabled, allowedLimit }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        justifyContent: 'right'
      }}
    >
      <SearchInput
        placeholder={'Search'}
        onSearch={onSearch}
        value={search}
        width={{ xs: 'max-content', md: '460px' }}
        matchDownLG={true}
        loading={loading}
      />

      <Button
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={() => navigate('/createAchievement')}
      >
        Create
      </Button>

      {disabled ? (
        <Tooltip
          title={`Maximum ${allowedLimit} badges can be created. You've reached the allowed limit.`}
          arrow
          placement="bottom-end"
        >
          <IconButton size="small" sx={{ p: 0 }}>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default AchievementFilters;
