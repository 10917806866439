import * as yup from 'yup';

export const badgesFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
  // description: yup.string().required('Required'),
  badge: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.number()
      })
    )
    .min(1, 'Required'),
  exercise: yup
    .array()
    .of(
      yup.object().shape({
        count: yup.string().required('Required'),
        goal: yup.number().required('Required')
      })
    )
    .min(1, 'Required')
});

export const updateBadgesFormSchema = yup.object().shape({
  name: yup.string().required('Required'),
  // description: yup.string().required('Required'),
  // badge: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       label: yup.string(),
  //       value: yup.number()
  //     })
  //   )
  //   .min(1, 'Required'),
  exercise: yup
    .array()
    .of(
      yup.object().shape({
        count: yup.string().required('Required'),
        goal: yup.number().required('Required')
      })
    )
    .min(1, 'Required')
});
