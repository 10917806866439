export const API_URLS = {
  LOGIN: '/VAHANA/v1/auth/admin/email/login',
  REGISTER: '/VAHANA/v1/auth/admin/email/register',

  //Dashboard stats
  TOTAL_USERS: '/VAHANA/v1/dashboard/total-users',
  ACTIVE_USERS: '/VAHANA/v1/dashboard/total-active-users',
  TRAFFIC_SOURCES: '/VAHANA/v1/dashboard/traffic-sources',
  ANNUAL_USER_COUNT: '/VAHANA/v1/dashboard/annual-users-count',

  //User management module
  GET_USERS: '/VAHANA/v1/users/admin/users-management',
  GET_USER_DETAIL: '/VAHANA/v1/userProfile/admin/:id',
  ACTIVATE_USER: '/VAHANA/v1/users/:id/activate',
  DELETE_USER_SESSIONS: '/VAHANA/v1/auth/delete-user-sessions',

  //Workout management module
  GET_WORKOUTS: '/VAHANA/v1/workouts/admin-all',
  DELETE_WORKOUT: '/VAHANA/v1/admin-workouts/:id',
  GET_WORKOUT_DETAIL: '/VAHANA/v1/admin-workouts/workouts/:id',
  UPDATE_WORKOUT: '/VAHANA/v1/admin-workouts/workouts/:id',
  CREATE_WORKOUT: '/VAHANA/v1/admin-workouts/workouts',
  GET_TARGET_AREAS: '/VAHANA/v1/admin-workouts/target-area',
  GET_FITNESS_GOALS: '/VAHANA/v1/admin-workouts/fitness-goals',

  //Fitness goals management module
  GET_GOALS: '/VAHANA/v1/fitness-goals/admin-all',
  GET_GOAL_DETAILS: '/VAHANA/v1/fitness-goals/:id',
  CREATE_GOAL: '/VAHANA/v1/fitness-goals/admin-goals',
  UPDATE_GOAL: '/VAHANA/v1/fitness-goals/admin-update/:id',
  DELETE_GOAL: '/VAHANA/v1/fitness-goals/admin-delete/:id',

  //File
  GET_UPLOADED_FILE_PATH: '/VAHANA/v1/files/uploaded-file-path/:type',

  //Leaderboard management
  GET_LEADERBOARD: '/VAHANA/v1/leadersBoard/admin-all',
  GET_LEADERBOARD_DETAILS: '/VAHANA/v1/leadersBoard/:id',
  CREATE_LEADERBOARD: '/VAHANA/v1/leadersBoard',
  UPDATE_LEADERBOARD: '/VAHANA/v1/leadersBoard/:id',
  DELETE_LEADERBOARD: '/VAHANA/v1/leadersBoard/:id',

  //Badges
  GET_ACHIEVEMENTS: '/VAHANA/v1/achievements/admin-all',
  GET_ALL_ACHIEVEMENTS: '/VAHANA/v1/achievements/admin-achievements',
  GET_ACHIEVEMENT_DETAILS: '/VAHANA/v1/achievements/admin-achievements/:id',
  CREATE_ACHIEVEMENT: '/VAHANA/v1/achievements/admin-achievements',
  UPDATE_ACHIEVEMENTS: '/VAHANA/v1/achievements/admin-achievements/:id',

  //Limit configs
  GET_ENTITY_LIMIT_SETTINGS: '/VAHANA/v1/entity-settings',
  UPDATE_ENTITY_LIMIT: '/VAHANA/v1/entity-settings/:id',
  GET_ENTITY_LIMIT: '/VAHANA/v1/entity-settings/:id'
};
