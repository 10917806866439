import { LOCAL_STORAGE_KEYS } from '../../utils/constants/constant';

// Set Access Token
const setAccessToken = (accessToken) => {
  // REMOVE THIS AFTER BE FIX
  localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
};

// Get Access Token
const getAccessToken = () => {
  try {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    return token;
  } catch (error) {
    return '';
  }
};

// Set Refresh Token
const setRefreshToken = (token) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token || null);
};

// Get Refresh Token
const getRefreshToken = () => {
  try {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    return token;
  } catch (error) {
    return '';
  }
};

// Set User Info
const setUserInfo = (info) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_INFO, JSON.stringify(info || {}));
};

// Get User Info
const getUserInfo = () => {
  try {
    const info = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO);
    return JSON.parse(info);
  } catch (error) {
    return '';
  }
};

const deleteLoginData = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_INFO);
};

const storageService = {
  setAccessToken,
  getAccessToken,
  setRefreshToken,
  getRefreshToken,
  setUserInfo,
  getUserInfo,
  deleteLoginData
};

export default storageService;
