import { API_URLS } from '../../utils/constants/apiUrls';
import storageService from '../../services/storageService/StorageService';
import Service from '../../services/api/Service';
import { request, success, failure } from '.';
import { AUTH_ACTIONS } from '../../utils/constants/actionKeys';

function login(loginModel) {
  return async (dispatch) => {
    try {
      dispatch(request(AUTH_ACTIONS.LOGIN_REQUEST));
      // API Calling
      const response = await Service.postService(API_URLS.LOGIN, loginModel, {});

      // Save Access Token
      const accessToken = response?.data?.token || null;
      storageService.setAccessToken(accessToken);

      // Save Refresh Token
      const refreshToken = response?.data.refreshToken || null;
      storageService.setRefreshToken(refreshToken);

      // Save User Info
      storageService.setUserInfo(response?.data?.user || {});

      dispatch(success(AUTH_ACTIONS.LOGIN_SUCCESS, response));

      return response;
    } catch (error) {
      dispatch(failure(AUTH_ACTIONS.LOGIN_FAILURE));
      throw error;
    }
  };
}

function logout() {
  return async (dispatch) => {
    try {
      dispatch(request(AUTH_ACTIONS.LOGOUT_REQUEST));
      await storageService.deleteLoginData();
      dispatch(success(AUTH_ACTIONS.LOGOUT_SUCCESS));
    } catch (error) {
      dispatch(failure(AUTH_ACTIONS.LOGOUT_FAILURE, error));
      throw error;
    }
  };
}

export { login, logout };
