import React from 'react';
import { SimpleCard } from '../../components';
import { Grid, Typography, styled, List, ListItem, Box, Paper } from '@mui/material';
import { REDUX_STATES } from '../../utils/constants/reduxStates';
import { useDispatch, useSelector } from 'react-redux';
import { getAction } from '../../store/actions/CRUDAction';
import { API_URLS } from '../../utils/constants/apiUrls';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'components/shared/Loader/Loader';
import { notify } from 'components/shared/Notify/notify';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const { ACHIEVEMENTS_DETAILS, RESPONSE, LOADING } = REDUX_STATES;

export const AchievementDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Redux State
  const {
    [ACHIEVEMENTS_DETAILS + RESPONSE]: badgesResponse = {},
    [ACHIEVEMENTS_DETAILS + LOADING]: badgesLoading = false
  } = useSelector((state) => state?.Crud);

  const getAchievementDetails = () => {
    dispatch(
      getAction(API_URLS.GET_ACHIEVEMENT_DETAILS.replace(':id', id), {}, ACHIEVEMENTS_DETAILS)
    ).catch((e) => notify.error(e?.message));
  };

  useEffect(() => {
    getAchievementDetails();
  }, []);

  return (
    <Container>
      {badgesLoading ? (
        <Loader loading={badgesLoading} />
      ) : (
        <SimpleCard title="Badge Detail">
          <SimpleCard
            sx={{
              pt: 1,
              pb: 2,
              px: 2,
              backgroundColor: '#f5f5f5',
              boxShadow: 'none !important',
              borderRadius: 0
            }}
          >
            <Box sx={{ alignItems: 'start', display: 'flex', gap: 2 }}>
              <Paper
                style={{
                  position: 'relative',
                  width: 100,
                  height: 90,
                  borderRadius: '50%'
                }}
              >
                <img
                  src={badgesResponse?.iconUrl || ''}
                  alt={`Icon`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    borderRadius: '50%'
                  }}
                />
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} lg={3}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Title
                  </Typography>
                  <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {badgesResponse?.title || ''}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={9} lg={9}>
                  <Typography variant="body1" sx={{ pt: '8px', fontWeight: 500 }}>
                    Description / Achievement Criteria
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: 'capitalize', whiteSpace: 'pre-line' }}
                  >
                    {badgesResponse?.description || ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </SimpleCard>

          {/* <Typography variant="body1" sx={{ pb: 1, pt: 2, pl: 0.5, fontWeight: 500 }}>
            Achievement Criteria:
          </Typography>

          {badgesResponse?.achievementCriteria?.map((item) => (
            <Grid container spacing={1} sx={{ pl: 1 }}>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Title
                </Typography>
                <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                  {item?.criteria?.title || ''}
                </Typography>
              </Grid>
            </Grid>
          ))} */}
        </SimpleCard>
      )}
    </Container>
  );
};
